import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import "../../assets/css/TermsAndCOnditions.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import {
	Video_Image_Btn,
	Video_Image,
	Video_Image_section,
} from "../../constant";
import { PrivacyPolicyApi } from "../../Redux/Auth/Auth_Action";

function TermsAndCOnditions() {
	const [state, setState] = useState();
	const dispatch = useDispatch();
	const PrivacyPolicyFunction = () => {
		dispatch(PrivacyPolicyApi())
			.then((res) => {
				setState(res?.data?.response?.data?.data?.[1]);
				// console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		PrivacyPolicyFunction();
	}, []);
	return (
		<>
			<section className="common-banner bgBanner">
				<Header />
				<div className="container">
					<div className="url-container">
						<h2 className="title">Terms And Conditions </h2>
					</div>
				</div>
			</section>

			{/* <!-- Terms and conditions Sec End Here --> */}
			<section className="terms-and-conditions">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div>
								<h5 className="terms-and-conditions-text">
									{state?.page_title}
								</h5>
							</div>
							<div
								dangerouslySetInnerHTML={{ __html: state?.content }}
								className="privacy-policy-text3"
							></div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Terms and conditions Sec End Here --> */}

			<Footer />
		</>
	);
}

export default TermsAndCOnditions;
