import React from 'react';
import axios from 'axios';
import { useEffect } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import ButtonWrapper from './ButtonWrapper';

const Test = () => {
  const accessToken = 'AdGRyMyfabg_0tqNbnKtDb3Sdy79JvHBOedyQKRZuMBt0TFBlJxbz_VqKgWHXp3xoL_wA5ATIIl-Nxmo';
  // replace with your actual Venmo access token
  const payload = {
    user_id: 'recipient_user_id',
    amount: 10.00,
    note: 'Payment note',
    audience: 'public',
  };

  const runMYFunc = (e) => {
    // e.preventDefault();
    // axios.post(('https://api.venmo.com/v1/payments'), payload, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${accessToken}`
    //   }
    // },).then((res) => {
    //   console.log(res);
    // }).catch((err) => {
    //   console.log(err);
    // })


  }



  // fetch('https://api.venmo.com/v1/payments', {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${accessToken}`
  //   },
  //   body: JSON.stringify(payload),
  // })
  //   .then(response => {
  //     if (!response.ok) {
  //       throw new Error('Payment failed');
  //     }
  //     return response.json();
  //   })
  //   .then(data => {
  //     console.log('Payment successful:', data);
  //   })
  //   .catch(error => {
  //     console.error('Payment failed:', error);
  //   });
  const initialOptions = {
    "client-id": "test",
    currency: "USD",
    intent: "capture",
    "data-client-token": "abc123xyz==",
  };

  return (
    <>
      <h1>Hello me working</h1>
      <div style={{ maxWidth: "750px", minHeight: "200px" }}>
        <PayPalScriptProvider
          options={{
            "client-id": "test",
            components: "buttons",
            currency: "USD"
          }}
        >
          <ButtonWrapper
            currency={"USD"}
            showSpinner={false}
          />
        </PayPalScriptProvider>
      </div>
    </>
  )
}

export default Test