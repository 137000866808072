import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../assets/css/Login.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginApi } from "../../Redux/Auth/Auth_Action";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const reduxState = useSelector((state) => state?.Auth);
	const [hideShowPass, setHideShowPass] = useState(false);
	const [state, setState] = useState({
		email: reduxState?.rememberMe ? reduxState?.email : "",
		password: reduxState?.rememberMe ? reduxState?.password : "",
	});
	const [checked, setChecked] = useState(false);
	const handleChange = (event) => {
		setChecked(event.target.checked);
	};

	// console.log(reduxState, "rember meeeeeeeeeeeeeeeeeee")

	const InputVal = (e) => {
		let { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const SubmitData = (e) => {
		e.preventDefault();
		let infoData = {
			email: state.email,
			password: state.password,
		};
		// console.log(infoData);
		dispatch(LoginApi(infoData, checked))
			.then((res) => {
				navigate("/");
			})
			.catch((e) => {
				// toast.error('invalid credientials',e);
				console.log(e);
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="LOGIN_PAGE">
			<section className="page-title">
				<Header bgColor={true} />
			</section>
			<section className="login">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="login-inner">
								<p className="login-title">Sign In</p>
								{/* <p className="main-para">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy aliquyam erat, sed diam voluptua.
                </p> */}
								<form onSubmit={SubmitData} methode="POST">
									<div className="field-set">
										<div className="input-cont">
											<p className="login-label">Email</p>
											<div className="pos-rel">
												<input
													className="mb-3"
													value={state.email}
													name="email"
													required
													onChange={InputVal}
													type="email"
													placeholder="John Doe@gmail.com"
												/>
											</div>
										</div>
										<div className="input-cont">
											<p className="login-label">Password</p>
											<div className="pos-rel">
												<VisibilityIcon
													className="visiblity_icon_posb"
													onMouseLeave={() => {
														setHideShowPass(false);
													}}
													onClick={() => {
														setHideShowPass(true);
													}}
												/>
												<input
													type={hideShowPass ? "text" : "password"}
													value={state.password}
													name="password"
													required
													onChange={InputVal}
													placeholder="********"
												/>
											</div>
										</div>
										<div className="remember-main">
											<div className="remember-cont">
												<input
													onChange={handleChange}
													checked={checked}
													type="checkbox"
													id="remember"
													className="remember-me-box check_cloeer label_same_remeberme mr-2"
												/>
												<label style={{ marginTop: "0.8rem" }}>
													Remember Me
												</label>
											</div>
											<div>
												<Link
													style={{ textDecoration: "underline" }}
													to="/forgot-password"
													className="login-label label_same_remeberme"
												>
													Forgot Password?
												</Link>
											</div>
										</div>
										<div>
											<button type="submit" className="btn signin-btn">
												Sign in
											</button>
										</div>
									</div>
								</form>
							</div>
							<div className="vl"></div>
						</div>
						<div className="col-lg-6">
							<div className="right-sec">
								<h3 className="right-title">Don't have an account?</h3>
								{/* <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam abore et dolore ma
                </p> */}
								<div>
									<button className="btn " onClick={() => navigate("/signup")}>
										Register your Account
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}

export default Login;
