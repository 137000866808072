import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/MyReviews.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import {
  VisaLogo,
  visaCard,
  mastercard,
  paypal,
  googlepay,
} from "../../constant";
import { AddCardApiCall, DeleteCardApiCall, GetDefaultCardApiCall, UpdateCardApiCall } from "../../Redux/Auth/Auth_Action";

function BillingDetails() {
  const d = new Date();
  const [card, setCard] = useState(null);
  const [state, setState] = useState({
    cardNumber: null,
    month: null,
    year: null,
    cvc: null,
  });
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.Auth);

  const CardHandler = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const getDefaultCard = () => {
    dispatch(GetDefaultCardApiCall(selector?.token))
      .then((res) => {
        setCard(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const UpdateDefaultCardFunc = (item) => {
    // console.log(item, 'xxxxxxxxxxxxxxxxxxxxxxxxxxx');
    let sendData = {
      card_id: item.id
    }
    dispatch(UpdateCardApiCall(selector?.token, sendData))
      .then((res) => {
        getDefaultCard();
        setTimeout(() => {
          toast.success("Default card changed successfully");
        }, 100);
      }).catch((err) => {
        console.log(err)
      })
  }

  const deleteCardFunc = (item) => {
    let sendData = {
      card_id: item.id
    }
    dispatch(DeleteCardApiCall(selector?.token, sendData))
      .then((res) => {
        getDefaultCard();
        setTimeout(() => {
          toast.success("Card removed successfully");
        }, 100);
      }).catch((err) => {
        console.log(err)
      })
  }

  const AddCardApiCallFunc = (e) => {
    e.preventDefault();
    if (state.cardNumber.length > 19) {
      toast.error("Card number must be less than 19 digit");
    }
    else if (state.year.length > 4) {
      toast.error("year must be less than 4 digit");
    }
    else if (state.month.length != 2) {
      toast.error("month must be  2 digit");
    }
    else if (state.cvc.length > 3) {
      toast.error("CVC must be less than 3 digit");
    } else {
      let sendData = {
        card: state.cardNumber,
        expiry_date: `${state.year}-${state.month}`,
        code: state.cvc,
      }
      dispatch(AddCardApiCall(selector?.token, sendData))
        .then((res) => {
          getDefaultCard();
          setTimeout(() => {
            toast.success("Card added successfully");
          }, 100);
          setState({
            ...state,
            cardNumber: null,
            month: null,
            year: null,
            cvc: null,
          })
          getDefaultCard();
        }).catch((e) => {
          toast.error(e?.response?.data?.errors);
          setState({
            ...state,
            cardNumber: null,
            month: null,
            year: null,
            cvc: null,
          })
        })
    }
  }

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength)
    }
  }

  useEffect(() => {
    getDefaultCard();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Billing_Details_Page">
      <section className="common-banner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">My Appointments </h2>
          </div>
        </div>
      </section>
      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >

                  <div className="container">
                    <p className="main-head ">Billing Details</p>
                    {card?.[0]
                      ?
                      <p className="main-head res_sml_save_crd">Saved Cards</p>
                      :
                      <p className="main-head res_sml_save_crd">No Card Found</p>
                    }
                    <div className="row">
                      {card?.[0]
                        ?
                        <> {card?.map((item, index) => {
                          return (
                            <div key={index + 3500} className="col-lg-6 p-3">
                              <div className="main-rating-modal">
                                <p className="my-card-handler">
                                  Card Details <br /> **********{item?.card_last4}
                                </p>
                                <div className="billing-form">
                                  <p className="use-default">Use as default</p>
                                  <div className="checking">
                                    <i className="fa fa-trash red_box_delete" onClick={() => { deleteCardFunc(item) }} aria-hidden="true"></i>
                                    <input
                                      onChange={() => { UpdateDefaultCardFunc(item) }}
                                      checked={item.default_card == 1}
                                      value={item.id}
                                      type="checkbox"
                                      className="checkBox_clr_brown"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}</>
                        :
                        null}
                    </div>
                    {/* <div className="row">
                      <div className="col">1 of 3</div>
                      <div className="col">2 of 3</div>
                      <div className="col">3 of 3</div>
                    </div> */}
                  </div>

                  <div className="container">
                    <p className="main-head-card-number">Enter New Card Details</p>
                    <form onSubmit={AddCardApiCallFunc}>
                      <div className="row pl-2">
                        {/* <div className="col"> */}
                        {/* <div className="main-rating-modal1"> */}
                        <div>
                          <p className="rating-text-modal"></p>
                        </div>
                        <div className="row">
                          <div className="col-sm-4 px-2">
                            <label>Card Number</label>
                            < input
                              value={state.cardNumber || ""}
                              // maxLength={"19"}
                              maxLength={"19"}
                              onInput={maxLengthCheck}
                              name="cardNumber"
                              //  onInput={this.maxLengthCheck} 
                              onChange={CardHandler}
                              required type='number' placeholder="XXXXXXXXXXXXXX" className="form-control crd-lght-plchldr" />
                          </div>
                          <div className="col-sm-2 px-2">
                            <label>Year</label>
                            <input
                              value={state.year || ""}
                              maxLength="4"
                              name="year"
                              onChange={CardHandler}
                              required type='number' placeholder={`${d.getFullYear()}`} className="form-control crd-lght-plchldr" />
                          </div>
                          <div className="col-sm-2 px-2">
                            <label>Month</label>
                            <input
                              value={state.month || ""}
                              name="month"
                              maxLength={2}
                              onChange={CardHandler}
                              required type='number' placeholder={`01`} className="form-control crd-lght-plchldr" />
                          </div>
                          <div className="col-sm-2 px-2">
                            <label>CVC</label>
                            <input
                              value={state.cvc || ""}
                              name="cvc"
                              // maxLength={3}
                              onChange={CardHandler}
                              required type='number' placeholder="XXX" className="form-control crd-lght-plchldr" />
                          </div>
                          <div className="col-sm-2 px-2">
                            <label className="text-white rezzz_nonw">{"Add Card "}</label>
                            <button type="submit" className="btn btn-light bt-add-card add_card_new_details pt-2">
                              Add Card
                            </button>
                          </div>
                          {/* </div> */}
                          {/* <div className="rating-wrapper-modal">
                              <div className="start-ul-modal">
                                mm/yy &nbsp;&nbsp;&nbsp; cvc
                            </div>
                            </div> */}
                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                    {/* <div className="row">
                      <div className="col">1 of 3</div>
                      <div className="col">2 of 3</div>
                      <div className="col">3 of 3</div>
                    </div> */}
                  </div>

                  {/*                 
                  <div className="container">

                    <div className="row">
                      <div className="col">
                        <div className="visacard-container">
                          <img
                            loading="lazy"
                            src={visaCard}
                            alt=""
                            style={{ width: 80, height: 40, marginTop: 10 }}
                          />
                          <img
                            loading="lazy"
                            src={googlepay}
                            alt=""
                            style={{ width: 80, height: 40, marginTop: 10 }}
                          />
                          <img
                            loading="lazy"
                            src={mastercard}
                            alt=""
                            style={{ width: 80, height: 40, marginTop: 10 }}
                          />
                          <img
                            loading="lazy"
                            src={paypal}
                            alt=""
                            style={{ width: 80, height: 40, marginTop: 10 }}
                          />
                        </div>
                      </div>
                      <div className="col"></div>
                    </div>
                  </div>
                */}
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default BillingDetails;
