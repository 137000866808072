import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/Contact.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import VideoPlayer from "react-background-video-player";
import {
	ContactUsApi,
	homeApi,
	siteSettingApi,
} from "../../Redux/Auth/Auth_Action";

function Contact() {
	const [homestate, setHomestate] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [siteSetting, setSiteSetting] = useState(null);
	const [state, setState] = useState({
		first_name: null,
		last_name: null,
		email: null,
		contact_number: null,
		description: null,
	});

	const InputVal = (e) => {
		let { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const ContactUsApiFunc = (e) => {
		e.preventDefault();

		let sendData = {
			first_name: state.first_name,
			last_name: state.last_name,
			email: state.email,
			contact_number: state.contact_number,
			description: state.description,
		};
		dispatch(ContactUsApi(sendData))
			.then((res) => {
				// console.log(res);
				toast.success("Your message has been sent successfuly");
				setTimeout(() => {
					navigate("/");
				}, 1000);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const SiteSettingFunc = () => {
		dispatch(siteSettingApi())
			.then((res) => {
				setSiteSetting(res?.data?.response?.data);
			})
			.catch((err) => {
				console.log(err);
			});
		dispatch(homeApi())
			.then((res) => {
				// console.log(res.data.response.data);
				setHomestate(res.data.response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		SiteSettingFunc();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="Contact_Page">
			<section className="common-banner">
				<Header />
				<VideoPlayer
					className="video"
					src={`${homestate?.image_url}${homestate?.video}`}
					autoPlay={true}
					muted={true}
					preload="auto"
				/>
				<div className="container">
					<div className="url-container">
						<h2 className="title">Contact Us</h2>
					</div>
				</div>
			</section>

			<section className="map_sec">
				<div className="container">
					<div className="google_map ">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26359294.213879213!2d-113.71754000532059!3d36.247089826313314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1650567615228!5m2!1sen!2s"
							width="100%"
							height="450"
							style={{ border: "0" }}
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</div>
				</div>
			</section>

			{/* <!-- Contact Sec Start Here --> */}
			<section className="contact_sec">
				<div className="container">
					<div className="row">
						<div className="col-md-3 right-sec">
							<div className="title">
								<h3>Send Us a Message</h3>
							</div>
							<div className="all_info">
								<div className="info_box">
									<div className="topbar">
										<div className="iconDv">
											<i className="fa fa-phone" aria-hidden="true"></i>
										</div>
										<div className="heading">
											<h4>Phone</h4>
										</div>
									</div>
									<div className="content">
										<ul className="info">
											<li>
												<span>Toll Free : </span>
												<a href={`tel:${siteSetting?.contact_phone}`}>
													{siteSetting?.contact_phone}
												</a>
												{/* <br />
                        <span>FAX : </span>
                        <a href={`tel:${siteSetting?.fax}`}>{siteSetting?.fax}</a> */}
											</li>
											{/* <li>
                        <span>Faxxxxxxxxx:</span>
                        <a href={`fax:${siteSetting?.fax}`}>{`tel:${siteSetting?.fax}`}</a>
                      </li> */}
										</ul>
									</div>
								</div>
								<div className="info_box">
									<div className="topbar">
										<div className="iconDv">
											<i className="fa fa-envelope" aria-hidden="true"></i>
										</div>
										<div className="heading">
											<h4>Email</h4>
										</div>
									</div>
									<div className="content">
										<ul className="info">
											<li>
												<a href={`mailto:${siteSetting?.contact_email}`}>
													{siteSetting?.contact_email}
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="info_box">
									<div className="topbar">
										<div className="iconDv">
											<i className="fa fa-map-marker" aria-hidden="true"></i>
										</div>
										<div className="heading">
											<h4>Address</h4>
										</div>
									</div>
									<div className="content">
										<ul className="info">
											<li>{siteSetting?.address}</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-8">
							<form onSubmit={ContactUsApiFunc}>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<input
												value={state.first_name}
												onChange={InputVal}
												name="first_name"
												required
												type="text"
												placeholder="First Name"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<input
												value={state.last_name}
												onChange={InputVal}
												name="last_name"
												required
												type="text"
												placeholder="Last Name"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<input
												value={state.email}
												onChange={InputVal}
												name="email"
												required
												type="email"
												placeholder="Email"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<input
												value={state.contact_number}
												onChange={InputVal}
												name="contact_number"
												required
												type="number"
												placeholder="Phone Number"
												className="form-control"
											/>
										</div>
									</div>
									<div className="col-md-12">
										<div className="form-group">
											<textarea
												value={state.description}
												onChange={InputVal}
												name="description"
												required
												placeholder="Your Message"
												className="form-control"
											></textarea>
										</div>
									</div>
									<div className="col-md-12 text-right">
										<button type="submit" className="btn py-2">
											Submit
										</button>
										{/* <div className="button-group-submit">
                      <button className="contact-btn">Submit</button>
                    </div> */}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Contact Sec End Here --> */}

			<Footer />
		</div>
	);
}

export default Contact;
