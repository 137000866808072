import { useSelect } from "@mui/base";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProfileImage } from "../../constant";

const ViewProfile = (props) => {
    const selector = useSelector((state) => state.Auth.user);
    // console.log(selector, ".......................................");
    return (
        <div className="tab-content" id="v-pills-tabContent">
            <div
                className="tab-pane fade active show"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
            >
                <p className="main-head">
                    {"Basic Information"}
                </p>
                <div className="container">
                    <div className="row info-profile">
                        <div className="col-md-12">
                            <div className="text-right">
                                <div className="user-upload">
                                    <div className="left-side">
                                        <div className="img-cont">
                                            <figure>
                                                <img
                                                    src={`${selector.image_url}${selector.profile_picture}`}
                                                    alt=""
                                                    className="image-fluid"
                                                />
                                            </figure>
                                        </div>
                                        {/* <div>
                                        <p className="upload-text1">Photo</p>
                                        <p className="upload-text2">
                                            Upload / Change Photo
                                        </p>
                                    </div> */}
                                    </div>
                                    <div className="right-side">
                                        <div className="text-right">
                                            <button
                                                className="change"
                                                onClick={() => props.setChangeTab(2)}
                                            >
                                                Edit Profile
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Accounts">
                                <div>
                                    <div className=""></div>
                                </div>
                            </div>


                            <>
                                <div className="row">
                                    <div className="col-lg-6 user-info">
                                        <div className="first-name">
                                            <h4 className="upload-text2">First Name</h4>
                                            <h3 className="upload-text1">{selector?.first_name}</h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 user-info">
                                        <div className="first-name">
                                            <h4 className="upload-text2">Last Name</h4>
                                            <h3 className="upload-text1">{selector?.last_name}</h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 user-info">
                                        <div className="first-name">
                                            <h4 className="upload-text2">Phone</h4>
                                            <h3 className="upload-text1">{selector?.phone_no}</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 user-info">
                                        <div className="first-name">
                                            <h4 className="upload-text2">Email</h4>
                                            <h3 className="upload-text1">{selector?.email}</h3>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 user-info">
                                        <div className="first-name">
                                            <h4 className="upload-text2">Password</h4>
                                            <h4 className="upload-text2">**********</h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 user-info">
                                        <div className="first-name">
                                            <h4 className="upload-text2">Date of Birth</h4>
                                            <h3 className="upload-text1">{selector?.date_of_birth}</h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 user-info">
                                        <div className="password-btn">
                                            <Link to="/change-password" className="change-password-btn">
                                                Change Password
                                            </Link>
                                            {/* <button
                          onClick={(e) => setPasswordChange(true)}
                        >
                          Change Password
                        </button> */}
                                        </div>
                                    </div>
                                </div>
                            </>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewProfile;