import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/Login.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { EmailImage, EmailImage1 } from "../../constant";
import { useDispatch } from "react-redux";
import { ForgotPasswordApi } from "../../Redux/Auth/Auth_Action";
import { useState } from "react";
import { toast } from "react-toastify";
function ForgotPassword() {
	const Navigate = useNavigate();
	const dispatch = useDispatch();
	const [state, setState] = useState(null);

	// const ForgotHandler = (e) => {
	//   e.preventDefault();
	//   Navigate("/reset-password");
	// };

	const ForgotPasswordApiCall = (e) => {
		e.preventDefault();
		let sendData = {
			email: state,
		};
		dispatch(ForgotPasswordApi(sendData))
			.then((res) => {
				// console.log(res);
				Navigate("/");
				toast.success("Link has been sent to your email");
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<section className="common-banner">
				<Header />
				<div className="container">
					<div className="url-container">
						<h2 className="title">Forgot Password?</h2>
					</div>
				</div>
			</section>

			<section className="login">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 mx-auto">
							<div className="card">
								<div className="card-body">
									<div className="login-inner">
										<div className="preview_img">
											<figure>
												<img src={EmailImage} className="img-fluid" />
											</figure>
										</div>
										<p className="forget-title">Email Verification</p>
										{/* <p className="forget-main-para">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                    </p> */}
										<div className="field-set">
											<div className="row">
												<div className="col-lg-12 forgert-input-cont">
													<form onSubmit={ForgotPasswordApiCall}>
														<div className="forget-preview_img">
															<img src={EmailImage1} className="forget-image" />

															<input
																required
																onChange={(e) => {
																	setState(e.target.value);
																}}
																type="email"
																placeholder="John Doe@gmail.com"
															/>
														</div>
														<div className="forget-password-btn">
															<button
																type="submit"
																className="btn verify-btn"
																// onClick={(e) => ForgotHandler(e)}
															>
																Verify
															</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default ForgotPassword;
