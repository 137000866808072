import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/Profile.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import EditProfile from "../../components/Profile/EditProfile";
import ViewProfile from "../../components/Profile/ViewProfile";
import SideBar from "../../components/SideBar/SideBar";
import { ProfileImage } from "../../constant";

function Profile() {

  const [changeTab, setChangeTab] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="PROFILE_PAGE">
      <section className="common-banner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">My Account</h2>
          </div>
        </div>
      </section>
      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-9">
              {changeTab === 1 && <ViewProfile setChangeTab={setChangeTab} />}
              {changeTab === 2 && <EditProfile setChangeTab={setChangeTab} />}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Profile;
