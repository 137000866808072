import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../assets/css/Footer.css";
import { logo } from "../../constant";
import { siteSettingApi } from "../../Redux/Auth/Auth_Action";
import Newsletter from "../NewsLetter/Newsletter";

const Footer = () => {
	const dispatch = useDispatch();
	const state = useSelector((state) => state.Auth.siteSetting);
	// console.log(state,'3333333333333333333333');
	// const [state, setState] = useState();
	// const SiteSettingFunc = () => {
	//   dispatch(siteSettingApi())
	//     .then((res) => {
	//       setState(res?.data?.response?.data);
	//     })
	//     .catch((err) => {
	//       console.log(err);
	//     })
	// }

	return (
		<>
			<Newsletter />

			<footer className="footer">
				<div className="container">
					<div className="row pb-5">
						<div className="col-lg-3">
							<div className="d-flex h-100">
								<div className="footer-1-content-wrapper">
									<Link to="#">
										<img
											src={`${state?.form_url}${state?.logo}`}
											className="img-fluid"
											alt=""
										/>
									</Link>
									<p className="fw-400"> {state?.footer_sentence}</p>
								</div>
								<div className="brdr_right_white"></div>
							</div>
						</div>
						<div className="col-lg-3 pr-0">
							<div className="d-flex h-100">
								<div className="footer-widget-2">
									<h5 className="quick_link">Quick Links</h5>
									<div className="footer-links-wrapper1">
										<ul className="footer-quick-link1 mr-3">
											<li>
												<Link to="/">Home</Link>
											</li>
											<li>
												<Link to="/about-us">About</Link>
											</li>
											<li>
												<Link to="/services">Services & Rates</Link>
											</li>
											<li>
												<Link to="/videos">Videos</Link>
											</li>
											<li>
												<Link to="/opportunities">Opportunities</Link>
											</li>
										</ul>
										<ul className="footer-quick-link2 pl-3">
											<li>
												<Link to="/contact">Contact Us</Link>
											</li>
											<li>
												<Link to="/blogs">Blogs</Link>
											</li>
											<li>
												<Link to="/faqs">FAQs</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="brdr_right_white"></div>
							</div>
						</div>
						<div className="col-lg-3 px-0">
							<div className="d-flex h-100">
								<div className="footer-widget-3 footer-res-amrgin">
									<h5>Address</h5>
									<div className="footer-links-wrapper">
										<ul>
											<li>
												<Link to="blog-detail.php">
													{state?.address}
													{}
												</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="brdr_right_white"></div>
							</div>
							{/* <div className="row px-0"> */}
							{/* <div className="col-lg-7 pl-0">
                  <div className="d-flex h-100">
                    <div className="footer-widget-3 footer-res-amrgin">
                      <h5>Address</h5>
                      <div className="footer-links-wrapper">
                        <ul>
                          <li>
                            <Link to="blog-detail.php">
                              {state?.address}
                              { }
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="brdr_right_white"></div>
                  </div>
                </div> */}
							{/* <div className="col-lg-5 ">
                  <div className="footer-widget-4">
                    <h5>Contact Us</h5>
                    <div className="footer-links-wrapper">
                      <ul>
                        <li>
                        <i className="fa fa-phone mr-2"></i>
                          <a href={`tel:${state?.contact_phone}`}>
                             {state?.contact_phone}
                          </a>
                        </li>
                        <li>
                          <i className="fa fa-envelope mr-2"></i>
                          <a href="mailto:Loremipsum@gmail.com">
                            {state?.contact_email}
                          </a>
                        </li>
                      </ul>
                      <ul className="social-links d-flex">
                        <li>
                          <i onClick={() => { window.open(`${state?.facebook}`, '_blank'); }} className="fa fa-facebook cursor_pointer text-white font_ico_footer mr-1"></i>
                        </li>
                        <li>
                          <i onClick={() => { window.open(`${state?.twitter}`, '_blank'); }} className="fa fa-twitter cursor_pointer text-white font_ico_footer mr-1"></i>
                        </li>
                        <li>
                          <i onClick={() => { window.open(`${state?.instagram}`, '_blank'); }} className="fa fa-instagram cursor_pointer text-white font_ico_footer mr-1"></i>
                        </li>
                        <li>
                          <i onClick={() => { window.open(`${state?.linkedin}`, '_blank'); }} className="fa fa-linkedin cursor_pointer text-white font_ico_footer mr-1"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
							{/* </div> */}
						</div>
						<div className="col-lg-3 px-0">
							<div className="footer-widget-4">
								<h5>Contact Us</h5>
								<div className="footer-links-wrapper">
									<ul>
										<li>
											<i className="fa fa-phone mr-2"></i>
											<a href={`tel:${state?.contact_phone}`}>
												{state?.contact_phone}
											</a>
										</li>
										<li>
											<i className="fa fa-envelope mr-2"></i>
											<a href="mailto:Loremipsum@gmail.com">
												{state?.contact_email}
											</a>
										</li>
									</ul>
									<ul className="social-links d-flex">
										<li>
											<i
												onClick={() => {
													window.open(`${state?.facebook}`, "_blank");
												}}
												className="fa fa-facebook cursor_pointer text-white font_ico_footer mr-1"
											></i>
										</li>
										<li>
											<i
												onClick={() => {
													window.open(`${state?.twitter}`, "_blank");
												}}
												className="fa fa-twitter cursor_pointer text-white font_ico_footer mr-1"
											></i>
										</li>
										<li>
											<i
												onClick={() => {
													window.open(`${state?.instagram}`, "_blank");
												}}
												className="fa fa-instagram cursor_pointer text-white font_ico_footer mr-1"
											></i>
										</li>
										<li>
											<i
												onClick={() => {
													window.open(`${state?.linkedin}`, "_blank");
												}}
												className="fa fa-linkedin cursor_pointer text-white font_ico_footer mr-1"
											></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="copy-right-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 set_footer_set">
								<p>{state?.copyright}</p>
							</div>
							<div className="col-lg-6 set_footer_set">
								<h6>
									<Link to="/privacy-policy">Privacy policy | </Link>
									<Link to="/terms-&-conditions"> Terms and conditions </Link>
									{/* <Link to="/refund-policy"> Refund Policy |</Link>
                  <Link to="/cookie-policy"> Cookie Policy |</Link>
                  <Link to="/deliver-info"> Delivery Policy</Link> */}
								</h6>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
