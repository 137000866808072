import React, { useEffect } from "react";
import "../../assets/css/ServicesAndRate.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { service1, service2, service3 } from "../../constant";
import { Link } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useState } from "react";
import VideoPlayer from "react-background-video-player";
import {
	ServicePaginateApi,
	ServicesGetAllApi,
	homeApi,
} from "../../Redux/Auth/Auth_Action";

function ServicesAndRate() {
	const [state, setState] = useState(null);
	const [links, setLinks] = useState(null);
	const [homestate, setHomestate] = useState();
	const dispatch = useDispatch();
	const getServiceApiFunc = (e) => {
		dispatch(ServicesGetAllApi())
			.then((res) => {
				setState(res?.data?.response?.data?.data);
				setLinks(res?.data?.response?.data?.links);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const MenuPagination = (e) => {
		dispatch(ServicePaginateApi(e))
			.then((res) => {
				setState(res?.data?.response?.data?.data);
				setLinks(res?.data?.response?.data?.links);
			})
			.catch((e) => {
				console.log(e);
			});
		// console.log(';zzzasdasd',e);
	};

	useEffect(() => {
		getServiceApiFunc();
		dispatch(homeApi())
			.then((res) => {
				// console.log(res.data.response.data);
				setHomestate(res.data.response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const serviceData = [
		{
			id: 1,
			img: service1,
		},
		{
			id: 2,
			img: service2,
		},
		{
			id: 3,
			img: service3,
		},
	];
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="Service_Page">
			<section className="common-banner">
				<Header />
				<VideoPlayer
					className="video"
					src={`${homestate?.image_url}${homestate?.video}`}
					autoPlay={true}
					muted={true}
					preload="auto"
				/>
				<div className="container">
					<div className="url-container">
						<h2 className="title">Service And Rates</h2>
					</div>
				</div>
			</section>

			{/* <!-- Services We Provide For Our Clients Sec Start Here --> */}
			<section className="service-rates">
				<div className="container">
					{/* <h2 className="service-title-service mb-4 pb-3">Services We Provide For Our Clients</h2> */}
					<h2 className="service-title-service mb-4 pb-3">Our Offerings</h2>
					<div className="col-lg-12 card-col px-0">
						{state?.map((item, index) => {
							return (
								<div className="col-lg-12 px-0" key={item.id}>
									<div className="service-card">
										<div className="service-img-box">
											<div className="service-image">
												<img
													src={`${item?.image_url}${item?.image}`}
													alt=" "
													className="image-fluid"
												/>
											</div>
											<div className="sevice_details-service">
												<div className="service-name-service">
													<p className="service-name-text ">{item?.name}</p>
													<p className="service-price-text">$ {item?.price}</p>
												</div>

												<p className="service-paragraph">{item?.description}</p>
												<div className="service-text-icon">
													{item?.points?.map((item, index) => {
														return (
															<p
																key={index + 6564}
																className="service-points-service"
															>
																<IoMdCheckmarkCircleOutline className="service-icon" />
																{item}
															</p>
														);
													})}
												</div>
												<div className="service-book-now-service">
													<Link to="/book">
														<div className="pos-rel d-flex">
															<u className="book-now-service">Book Now</u>
															<div className="right_arrow_serviceRates">
																<i
																	className=" fa fa-arrow-right"
																	aria-hidden="true"
																></i>
															</div>
														</div>
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div className="col-lg-12 mb-5 text-center">
						<div className="text-right">
							{links?.map((item, index) => {
								if (item.label === "&laquo; Previous") {
									item.label = "Previous";
									return (
										<button
											key={index + 100}
											onClick={() => {
												MenuPagination(item.url);
											}}
											disabled={item.url === null ? true : false}
											className="btn btn-light bt-pagination-all"
										>
											{item.label}
										</button>
									);
								} else if (item.label === "Next &raquo;") {
									item.label = "Next";
									return (
										<button
											key={index + 100}
											onClick={() => {
												MenuPagination(item.url);
											}}
											disabled={item.url === null ? true : false}
											className="btn btn-light bt-pagination-all"
										>
											{item.label}
										</button>
									);
								} else {
									return (
										<button
											key={index + 100}
											onClick={() => {
												MenuPagination(item.url);
											}}
											disabled={item.url === null ? true : false}
											className="btn btn-light bt-pagination-all"
										>
											{item.label}
										</button>
									);
								}
							})}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Services We Provide For Our Clients Sec End Here --> */}
			<Footer />
		</div>
	);
}

export default ServicesAndRate;
