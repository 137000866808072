import { ERROR_TYPE, REMOVE_ERROR } from '../../Redux/services/Types';

const initialState = {
    error: [],
    filterError: [],
};

const ErrorReducer = (state = initialState, action) => {
    // console.log("working from error reducer action", action);

    switch (action.type) {
        case ERROR_TYPE:
            return {
                ...state,
                error: [
                    ...state.error,
                    ...action.payload
                ]
            }

        case REMOVE_ERROR:
            return {
                ...state,
                error: [...state.error.filter((item, key) => key != action.payload)]
            }

        // saving token tfor authentication     
        // case "SAVE_TOKEN":
        //     return {
        //         ...state,
        //         token: action.userInfo.token,
        //         user: action.userInfo.user,
        //     }

        // deleting token for logout functionality

        default: {
            return state
        }
    }
}

export default ErrorReducer;