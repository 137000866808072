import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { profile1 } from '../../constant';
import { getTesitmonialApiCall } from '../../Redux/Auth/Auth_Action';
import { Rating } from 'react-simple-star-rating';

function TestimonialSlider() {
    const dispatch = useDispatch();
    const [testimonail, setTestimonial] = useState();
    useEffect(() => {
        dispatch(getTesitmonialApiCall())
            .then((res) => {
                setTestimonial(res?.data?.response?.data);
                // console.log(res?.data?.response?.data, '22222222222222222222222222');
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const data = [
        {
            id: 1,
        },
        {
            id: 2,
        },
        {
            id: 3,
        },
        {
            id: 4,
        },
    ]

    return (
        <div>
            <Slider {...settings}>
                {
                    testimonail?.map((item, index) => {
                        return (
                            <div className="testimonails-wrapper" key={index}>
                                <div className="testimonails-img-wrapper text-center">
                                    <figure>
                                        <img src={`${item?.user?.image_url}${item?.user?.profile_picture}`} className="img-fluid img_slider_testimonialsz" alt="" />
                                    </figure>
                                </div>
                                <div className="testimonails-content-wrapper text-center">
                                    <p>{item?.description}</p>
                                    <h6>{item?.user?.first_name}{" "}{item?.user?.last_name}</h6>
                                    <div className="rating-wrapper">
                                        <Rating
                                            initialValue={parseInt(item?.rating)}
                                            readonly

                                        // onClick={handleRating}
                                        /* Available Props */
                                        />
                                        {/* <ul>
                                            {[0, 1, 2, 3, 4].map((user_item, i) => {
                                                // console.log(parseInt(item?.rating) == index);
                                                if (parseInt(item?.rating) <= i + 1)
                                                    return (
                                                        <li key={index + 2200}><i className="fa fa-star"></i></li>
                                                    )
                                            })}
                                        </ul> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default TestimonialSlider