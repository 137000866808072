import Auth from "../Redux/Auth/Auth_Reducer";
import ErrorReducer from '../Redux/Errors/Reducer';

import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    Auth: Auth,
    ErrorReducer: ErrorReducer,
});

export default rootReducer;