import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import "../../assets/css/Videos.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import VideoPlayer from "react-background-video-player";
import {
	Video_Image_Btn,
	Video_Image,
	Video_Image_section,
} from "../../constant";
import {
	VedioPaginateApi,
	VideosApi,
	homeApi,
} from "../../Redux/Auth/Auth_Action";
import play_btn from "../../assets/img/play_btn.png";

function Videos() {
	const [homestate, setHomestate] = useState();
	const [state, setState] = useState();
	const [bigVideo, setBigVideo] = useState();
	const [links, setLinks] = useState();
	const dispatch = useDispatch();
	const VideosApiFunc = () => {
		dispatch(VideosApi())
			.then((res) => {
				setBigVideo(res?.data?.response?.data?.data[0]);
				setState(res?.data?.response?.data?.data);
				setLinks(res?.data?.response?.data?.links);
				// console.log(res?.data?.response?.data, 'ssssssssssssssssssssssssssssssss');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getVideoItem = (item) => {
		setBigVideo(item);
		window.scrollTo(0, 400);
	};

	const MenuPagination = (e) => {
		dispatch(VedioPaginateApi(e))
			.then((res) => {
				setState(res?.data?.response?.data?.data);
				setLinks(res?.data?.response?.data?.links);
			})
			.catch((e) => {
				console.log(e);
			});
		// console.log(';zzzasdasd',e);
	};

	useEffect(() => {
		VideosApiFunc();
		dispatch(homeApi())
			.then((res) => {
				// console.log(res.data.response.data);
				setHomestate(res.data.response.data);
			})
			.catch((err) => {
				console.log(err);
			});
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="Video_Page">
			<section className="common-banner">
				<Header />
				<VideoPlayer
					className="video"
					src={`${homestate?.image_url}${homestate?.video}`}
					autoPlay={true}
					muted={true}
					preload="auto"
				/>
				<div className="container">
					<div className="url-container">
						<h2 className="title">Videos </h2>
					</div>
				</div>
			</section>

			{/* <!-- Video Sec End Here --> */}
			<section className="video-section">
				<div className="container">
					<div className="row">
						{state?.map((item, index) => {
							// console.log(item)
							return (
								<div className="col-lg-12 pos-rel">
									<p className="name_caption_video bg_big_video">
										{item?.caption}
									</p>
									<video
										className="main_full_video"
										width={"100%"}
										src={`${item?.video_url}${item?.filename}`}
										controls
									>
										Your browser does not support the video tag.
									</video>
								</div>
							);
						})}
					</div>
				</div>
				{/* <div className="container">
          <div className="row">
            {state?.map((item, index) => {
              return (
                <div key={index + 6564} onClick={() => { getVideoItem(item) }} className="col-lg-4 pos-rel">
                  <img className="play_btn_videos" src={play_btn} />
                  <p className="name_caption_video">{item?.caption}</p>
                  <img className="obj_fit_cover w-100 my-3 cursor_pointer video_thumbnails" src={`${item?.video_url}/thumbs/${item?.thumbnail}`} />
                </div>
              )
            })}
          </div>
        </div> */}
				<div className="container">
					<div className="row mt-3">
						<div className="col-lg-12 text-center">
							<div className="text-right">
								{links?.map((item, index) => {
									if (item.label === "&laquo; Previous") {
										item.label = "Previous";
										return (
											<button
												key={index + 100}
												onClick={() => {
													MenuPagination(item.url);
												}}
												disabled={item.url === null ? true : false}
												className="btn btn-light bt-pagination-all"
											>
												{item.label}
											</button>
										);
									} else if (item.label === "Next &raquo;") {
										item.label = "Next";
										return (
											<button
												key={index + 100}
												onClick={() => {
													MenuPagination(item.url);
												}}
												disabled={item.url === null ? true : false}
												className="btn btn-light bt-pagination-all"
											>
												{item.label}
											</button>
										);
									} else {
										return (
											<button
												key={index + 100}
												onClick={() => {
													MenuPagination(item.url);
												}}
												disabled={item.url === null ? true : false}
												className="btn btn-light bt-pagination-all"
											>
												{item.label}
											</button>
										);
									}
								})}
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <!-- Video Sec End Here --> */}

			<Footer />
		</div>
	);
}

export default Videos;
