import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import "../../assets/css/Profile.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { AppointmentPagination, GetBookingDetailsApi, UploadFormAPiCall, CancelAppointme } from "../../Redux/Auth/Auth_Action";
import { Dialog, Modal, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";

function MyAppoinment() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.Auth.token);
  const user = useSelector((state) => state.Auth.siteSetting);
  const [state, setState] = useState(null);
  const [links, setLinks] = useState(null);
  const [file, setFile] = useState(null);
  const [id, setId] = useState(null);
  const [cancelId, setCancelId] = useState(null);
  // cancel appointment modal 
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = (id) => {
    setCancelId(id);
    setOpen1(true);
    // console.log('i am working')
  };

  const handleClose1 = () => {
    setOpen1(false);
    setCancelId(null);
  };
  // cancel appointment modal


  // upload form
  const uploadFileFunc = (event) => {
    // console.log(event, 'fffffffffffffffff');
    if ((event.target.files[0].size / 1000000) <= 5) {
      setFile(event.target.files[0]);
      // setSelectedImage(URL.createObjectURL(event.target.files[0]));
    } else {
      toast.error("file size is greater than 5 mb");
    }
  }

  // console.log(file, 'xxxxxxxxxxxxxxxxxxxxxxxx');

  const GetPreviousBookingFunc = (type) => {
    dispatch(GetBookingDetailsApi(selector, type ? type : 1))
      .then((res) => {
        setState(res?.data?.response?.data?.data);
        setLinks(res?.data?.response?.data?.links);
        // console.log(res?.data?.response?.data?.data, 'upcoming booking');
      }).catch((err) => {
        console.log(err);
      })
  }


  const MenuPagination = (e) => {
    dispatch(AppointmentPagination(selector, e))
      .then((res) => {
        setState(res?.data?.response?.data?.data);
        setLinks(res?.data?.response?.data?.links);
      })
      .catch((e) => {
        console.log(e);
      })
    // console.log(';zzzasdasd',e);
  }

  const UploadFormFile = () => {
    let formData = new FormData();    //formdata object
    formData.append('id', id);
    formData.append('file', file);
    // console.log(file, 'xcccccccccccc');
    dispatch(UploadFormAPiCall(selector, formData))
      .then((res) => {
        toast.success("Form uploaded successfully");
        handleClose();
        setFile(null);
        // console.log(res, 'xxxxxxxxxxxxxxxxxxxxxxxxxxxx');
      })
      .catch((e) => {
        console.log(e);
      })
  }

  // const CancelAppointment = (id) => {
  //   setOpenCancel(true);
  //   setCancelId(id)
  // }

  const CancelApiCall = () => {
    let sendData = {
      appointment_id: cancelId
    }
    dispatch(CancelAppointme(selector, sendData))
      .then((res) => {
        // console.log(res);
        toast.success("Appointment has been cancelled successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    GetPreviousBookingFunc();
  }, []);

  return (
    <>
      <section className="common-banner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">My Appointments </h2>
          </div>
        </div>
      </section>

      {/* modal on cancel appointment */}
      <Dialog
        maxWidth={"md"}
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="payment_modal p-4">
          <div className='container-fluid modal_payment_dialog'>
            <div className="d-flex">

            </div>
            <h4 className="my-4">Are you sure want to cancel</h4>
            {/* <p>Dialog box here</p> */}
            {/* <div className="pos-rel">
              <input onChange={uploadFileFunc} type="file" />

            </div> */}
            <div className="row mt-4">
              <div className="col-lg-12 text-right">

                <button onClick={handleClose1} className="btn btn-light mr-1">No</button>
                <button onClick={CancelApiCall} className="btn btn-light">Yes</button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* modal on cancel appoinment */}

      {/* modal on upload appoinment */}
      <Dialog
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="payment_modal p-4">
          <div className='container-fluid modal_payment_dialog'>
            <div className="d-flex">

            </div>
            <h4 className="mb-4">Upload Form </h4>
            {/* <p>Dialog box here</p> */}
            <div className="pos-rel">
              <input onChange={uploadFileFunc} type="file" />

            </div>
            <div className="row mt-4">
              <div className="col-lg-12 text-right">

                <button onClick={handleClose} className="btn btn-light mr-1">Cancel</button>
                <button onClick={UploadFormFile} className="btn btn-light">Save</button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* modal on upload appoinment  */}

      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p className="main-head">My Appointments </p>
                  <ul className="nav nav-tabs" role="tablist">
                    <div className="slider" />
                    <li className="my-appoinment-nav-item">
                      {/* <a
                          className="nav-link active"
                          id="home-tab"
                          data-toggle="tab"
                          href="#home"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                        Previous
                        </a> */}
                      <Button
                        variant="outlined"
                        disableElevation
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        onClick={() => { GetPreviousBookingFunc(1) }}
                        style={{ textTransform: "unset" }}
                      >
                        Previous
                      </Button>
                    </li>
                    <li className="my-appoinment-nav-item">
                      {/* <a
                              className="nav-link"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#profile"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              Upcoming
                            </a> */}
                      <Button
                        variant="outlined"
                        disableElevation
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        style={{ textTransform: "unset" }}
                        onClick={() => { GetPreviousBookingFunc(2) }}
                      >
                        Upcoming
                      </Button>
                    </li>
                    <li className="my-appoinment-nav-item">
                      {/* <a
                              className="nav-link"
                              id="cancelled-tab"
                              data-toggle="tab"
                              href="#cancelled"
                              role="tab"
                              aria-controls="cancelled"
                              aria-selected="false"
                            >
                              cancelled
                            </a> */}
                      <Button
                        variant="outlined"
                        disableElevation
                        className="nav-link"
                        id="cancelled-tab"
                        data-toggle="tab"
                        href="#cancelled"
                        role="tab"
                        aria-controls="cancelled"
                        aria-selected="false"
                        onClick={() => { GetPreviousBookingFunc(3) }}
                        style={{ textTransform: "unset" }}
                      >
                        Cancelled
                      </Button>
                    </li>
                  </ul>
                  <div className="row info-profile1">
                    <div className="col-md-12">
                      <div className="tile" id="tile-1">
                        {/* Nav tabs */}

                        {/* Tab panes */}
                        <div className="tab-content">
                          <div
                            className="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow >
                                  <TableCell className="my-appoinment-heading">Date</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Time</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Service</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">
                                    Appoinment For
                                  </TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {state?.map((item, index) => {
                                  return (
                                    <TableRow key={index + 6564}>
                                      <TableCell className="table-td" align="right">
                                        {item?.date}
                                      </TableCell>
                                      <TableCell className="table-td" align="right">
                                        {item?.time}
                                      </TableCell>
                                      <TableCell className="table-td" align="right">
                                        {item?.service?.name}
                                      </TableCell>
                                      <TableCell className="table-td" align="right">{item?.types?.name}</TableCell>
                                      <TableCell
                                        className="table-td1"
                                        align="right"
                                      >
                                        <p className="text-dark">{item?.status === 0 ? "pending" : item?.status === 1 ? "Approved" : "Cancelled"}</p>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>

                          </div>

                          <div
                            className="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell className="my-appoinment-heading">Date</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Time</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Service</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">
                                    Appoinment For
                                  </TableCell>
                                  <TableCell className="my-appoinment-heading" align="right" colSpan={3} style={{ textAlign: "center" }}>Action</TableCell>
                                  {/* <TableCell align="right"></TableCell>
                                  <TableCell align="right"></TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {state?.map((item, index) => {
                                  return (
                                    <TableRow key={index + 84342}>
                                      <TableCell align="right" className="table-td">
                                        {item?.date}
                                      </TableCell>
                                      <TableCell align="right" className="table-td">
                                        {item?.time}
                                      </TableCell>
                                      <TableCell align="right" className="table-td">
                                        {item?.service?.name}
                                      </TableCell>
                                      <TableCell align="right" className="table-td">
                                        {item?.types?.name}
                                      </TableCell>
                                      <TableCell align="right" className="table-td-b">
                                        {/* <u>Download Form</u> */}
                                        <Tooltip title="Download Form">
                                          <a href={`${user.form_url}${user.form}`} download>
                                            <i className="fa fa-download download_form cursor_pointer"></i>
                                          </a>
                                        </Tooltip>
                                      </TableCell>
                                      {item?.status === 0
                                        ?
                                        <TableCell align="right" className="table-td-b">
                                          <u onClick={() => { handleClickOpen1(item?.id) }} className="cursor_pointer">Cancel</u>
                                        </TableCell>
                                        :
                                        null}
                                      {item?.file
                                        ?
                                        <TableCell align="right" className="table-td-b">
                                          <u className="cursor_pointer color_dull_disalbled">Upload Form</u>
                                        </TableCell>
                                        :
                                        <TableCell align="right" className="table-td-b">
                                          <u onClick={() => { handleClickOpen(item?.id) }} className="cursor_pointer">Upload Form</u>
                                        </TableCell>
                                      }

                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="cancelled"
                            role="tabpanel"
                            aria-labelledby="cancelled-tab"
                          >
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell className="my-appoinment-heading">Date</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Time</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Service</TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">
                                    Appoinment For
                                  </TableCell>
                                  <TableCell className="my-appoinment-heading" align="right">Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {
                                  state?.map((item, index) => {
                                    return (
                                      <TableRow key={index + 24862}>
                                        <TableCell className="table-td" align="right">
                                          {item?.date}
                                        </TableCell>
                                        <TableCell className="table-td" align="right">
                                          {item?.time}
                                        </TableCell>
                                        <TableCell className="table-td" align="right">
                                          {item?.types?.name}
                                        </TableCell>
                                        <TableCell className="table-td" align="right">
                                          {item?.service?.name}
                                        </TableCell>
                                        <TableCell align="right" className="table-td-b">
                                          <u
                                            // onClick={() => { handleClickOpen1(item?.id) }} 
                                            className="cursor_pointer">Cancelled</u>
                                        </TableCell>
                                        {/* <TableCell
                                          className="table-td"
                                          align="right"
                                          style={{ color: "red" }}
                                        >
                                          cancelled
                                        </TableCell> */}
                                      </TableRow>
                                    )
                                  })
                                }


                              </TableBody>
                            </Table>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-12 text-right text_res_center_appointment">
                      {links?.map((item, index) => {
                        if (item.label === "&laquo; Previous") {
                          item.label = "Previous";
                          return (
                            <button key={index + 100}
                              onClick={() => { MenuPagination(item.url) }}
                              disabled={(item.url === null ? true : false)}
                              className='btn btn-light bt-pagination-all'>
                              {item.label}
                            </button>
                          )
                        } else if (item.label === "Next &raquo;") {
                          item.label = "Next";
                          return (
                            <button key={index + 100}
                              onClick={() => { MenuPagination(item.url) }}
                              disabled={(item.url === null ? true : false)}
                              className='btn btn-light bt-pagination-all'>
                              {item.label}
                            </button>
                          )
                        } else {
                          return (
                            <button key={index + 100}
                              onClick={() => { MenuPagination(item.url) }}
                              disabled={(item.url === null ? true : false)}
                              className='btn btn-light bt-pagination-all'>
                              {item.label}
                            </button>
                          )
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default MyAppoinment;
