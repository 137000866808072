import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Rating } from 'react-simple-star-rating';
import { toast } from "react-toastify";
// import "../../assets/css/Profile.css";
import "../../assets/css/MyReviews.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { PostReviewApi } from "../../Redux/Auth/Auth_Action";

function MyPostReview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector((state) => state?.Auth?.token);

  const [rating, setRating] = useState(1);
  const [review, setReview] = useState("");

  const handleRating = (rate) => {
    setRating(rate)
  }

  const PostMyReviewFunc = (e) => {
    e.preventDefault();
    if (!review) {
      toast.error("Please give review");
    } else {
      let SendData = {
        rating: rating,
        description: review,
      }
      dispatch(PostReviewApi(selector, SendData))
        .then((res) => {
          // console.log(res);
          toast.success("Review sent successfully");
          setTimeout(() => {
            navigate('/');
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="My_Post_Review">
      <section className="common-banner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">My Appointments </h2>
          </div>
        </div>
      </section>
      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p className="main-head">My Reviews</p>

                  <div className="row info-profile">
                    <div className="col-md-12">
                      <div className="form-group textareaInput">
                        <label htmlFor="exampleFormControlTextarea1">
                          What do you think?
                        </label>
                        <textarea
                          onChange={(e) => { setReview(e.target.value) }}
                          className="form-control font-pop"
                          id="exampleFormControlTextarea1"
                          rows="3"
                          placeholder="Write Review"
                          style={{ reSize: "none"}}
                        ></textarea>
                      </div>

                      {/* <Rating onClick={handleRating} ratingValue={rating} /> */}
                      <div className="main-rating">
                        <div>
                          <p className="rating-text">Rating</p>
                        </div>
                        <div className="mt-2 mr-4">
                          <Rating
                            initialValue={1}
                            onClick={handleRating}
                          /* Available Props */
                          />
                        </div>

                        {/* <div className="rating-wrapper">
                         
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-right">
                          <button onClick={PostMyReviewFunc} className="btn px-5 py-2 mt-2">Send</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default MyPostReview;
