import { AFTER_LOGIN, SAVE_TOKEN, } from '../services/Types';

const initialState = {
    user: {},
    token: null,
    addToCart: [],
    rememberMe: true,
    password: undefined,
    email : undefined,
    siteSetting: null,
    activePackage: null,
    firstSaveData: null,
    secondSaveData: null,
};

const Auth = (state = initialState, action) => {
    // console.log('auth reducer from reducer', action.payload);
    switch (action.type) {

        // saving token tfor authentication     

        case SAVE_TOKEN:
            // console.log("auth reducer jaaaaaaa", action.payload);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.userInfo,
                rememberMe: action.payload.checked,
                password: action.payload.password,
                email: action.payload.email,
            }

        case AFTER_LOGIN:
            // console.log("working reeeeedddduuuuucccccr", action.payload);
            return {
                ...state,
                activePackage: action.payload,
            }

        case "DEL_TOKEN":
            // console.log(state.user, ' state user from auth reducer del token');
            return {
                ...state,
                token: null,
                user: null,
            }

        case "UPDATE_PROFILE":
            return {
                ...state,
                user: action.payload.e,
            }

        case `REMEMBER_ME`:
            // console.log('auth reducer from reducer', action.payload);
            return {
                ...state,
                rememberMe: [...action.payload]
            }

        case "SITE_SETTING":
            // console.log('from site setting action', action);
            return {
                ...state,
                siteSetting: action.payload,
            }

        default: {
            // console.log("default working")
            return state
        }
    }
}

export default Auth;