import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/css/MyReviews.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SideBar from "../../components/SideBar/SideBar";
import { VisaLogo } from "../../constant";
import { changePasswordApi } from "../../Redux/Auth/Auth_Action";
import VisibilityIcon from '@mui/icons-material/Visibility';

function ChangePassword() {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.Auth.token);
  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passLoading, setPassLoading] = useState(false);
  const [hideShowPass, setHideShowPass] = useState(false);
  const [hideShowNewPass, setHideShowNewPass] = useState(false);
  const [hideShowNewConfPass, setHideShowConfPass] = useState(false);

  const changePassword = (e) => {
    e.preventDefault();
    let sendData = {
      current_password: currentPassword,
      password: password,
      confirm_password: confirmPassword,
    }
    dispatch(changePasswordApi(selector, sendData))
      .then((res) => {
        // console.log(res);
        toast.success("Password changed successfully");
        setTimeout(() => {
          dispatch({ type: "DEL_TOKEN" }).then(() => {
            navigate('/login');
          })
        }, 1500);
      }).catch((err) => {
        console.log(err);
      })
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // console.log(currentPassword, 'ssssssssssssssssss')

  return (
    <div className="CHANGE_PASSWORD_PAGE">
      <section className="common-banner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">My Profile </h2>
          </div>
        </div>
      </section>
      <section className="profile">
        <div className="container">
          <div className="row profile-row">
            <SideBar />
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <p className="main-head">Change Password</p>

                  <div className="row info-profile">
                    <div className="security-form-wrapper pt-5">
                      <form action="">
                        <div className="form-group row">
                          <div className="col-lg-6 pos-rel mb-3">
                            <VisibilityIcon onMouseLeave={() => { setHideShowPass(false) }} onClick={() => { setHideShowPass(true) }} className="visiblity_icon_posb" />
                            <input
                              type={hideShowPass ? "text" : "password"}
                              className="form-control"
                              placeholder="Current Password"
                              value={currentPassword}
                              onChange={(e) =>
                                setCurrentPassword(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-lg-6 pos-rel mb-3">
                            <VisibilityIcon onMouseLeave={() => { setHideShowNewPass(false) }} onClick={() => { setHideShowNewPass(true) }} className="visiblity_icon_posb" />
                            <input
                              type={hideShowNewPass ? "text" : "password"}
                              className="form-control"
                              placeholder="New Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          {/* <div className="form-group row"> */}
                          <div className="col-lg-6 pos-rel mb-3">
                            <VisibilityIcon onMouseLeave={() => { setHideShowConfPass(false) }} onClick={() => { setHideShowConfPass(true) }} className="visiblity_icon_posb" />
                            <input
                              type={hideShowNewConfPass ? "text" : "password"}
                              className="form-control"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>
                          <div className="col-lg-6 mb-3 res_center_text_edit_password">
                            <button
                              className="btn btn-submit-password-change"
                              onClick={(e) => changePassword(e)}
                              disabled={passLoading}
                            >
                              {"Save Password "}
                            </button>
                          </div>
                        </div>

                        {/* </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ChangePassword;
