import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/BookAnAppoinment.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAppointmentServiceApiCall, getAppointmentTypeApiCall, GetDefaultCardApiCall, StoreAppointmentBookingApiCall } from "../../Redux/Auth/Auth_Action";
import { toast } from "react-toastify";

function BookAnAppoinment() {
  const dispatch = useDispatch();
  const location = useLocation();
  const d = new Date();
  const new_date = new Date().toISOString().slice(0, 10);
  const navigate = useNavigate();
  const selector = useSelector((state) => state.Auth);
  const [state, setState] = useState({
    service: null,
    appoint: null,
    date: null,
    time: null,
  });
  const [defaultCard, setDefaultCard] = useState(null);
  const [services, setServices] = useState(null);
  const [type, setType] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const submitButton = () => {
    if (!state.service || !state.time || !state.date || !state.appoint) {
      toast.error("Please fill all fields");
    } else {
      setShow(true);
    }
  }

  const handleShow = () => setShow(true);

  const [showanothermodal, setShowanothermodal] = useState(false);

  const ImputVal = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const handleCloseAnotherModalfunction = () => {
  };

  const StoreApoointMentFunc = () => {
    let SendData = {
      service_id: state.service,
      type: state.type,
      first_name: selector.user.first_name,
      last_name: selector.user.last_name,
      email: selector.user.email,
      date_of_birth: selector.user.date_of_birth,
      date: state.date,
      time: state.time,
      type: state.appoint,
      card_id: defaultCard,
    }
    dispatch(StoreAppointmentBookingApiCall(selector?.token, SendData))
      .then((res) => {
        handleShowAnotherModal(true);
        navigate('/')
        handleClose(false);
      }).catch((err) => {
        console.log(err);
        toast.error("An Error occured during booking")
      })
  }

  const handleCloseAnotherModal = () => {
    setShowanothermodal(false);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const handleShowAnotherModal = () => setShowanothermodal(true);

  const getServicesFunc = () => {
    dispatch(getAppointmentServiceApiCall())
      .then((res) => {
        setServices(res?.data?.response?.data?.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getTypeFunc = () => {
    dispatch(getAppointmentTypeApiCall())
      .then((res) => {
        setType(res?.data?.response?.data?.data);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getDefaultCard = () => {
    dispatch(GetDefaultCardApiCall(selector?.token))
      .then((res) => {
        let defaultCardDup = res?.data?.response?.data.filter((item) => {
          return item.default_card == 1
        })
        setDefaultCard(...defaultCardDup);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getServicesFunc();
    getTypeFunc();
    getDefaultCard();
  }, []);
  return (
    <div className="BOOK_PAGE">
      <section className="common-banner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Appoinment Request</h2>
          </div>
        </div>
      </section>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-inner pb-5">
                <p className="Appoinment-Request-title">Appoinment Request</p>

                <div className="field-set">
                  <div className="row">
                    <div className="col-lg-6 input-cont pos-rel my-0 mt-2">
                      {/* <i class="fa fa-chevron-down select_down_arrow_adjustable" aria-hidden="true"></i> */}
                      <div className="form-group my-0">
                        <label htmlFor="inputState"> Select Service</label>
                        <select value={state.service} onChange={ImputVal} name="service" id="inputState" className="form-control">
                          <option selected disabled value={null}>Select Service</option>
                          {services?.map((item) => {
                            return (
                              <option key={item.id} value={item.id}>{item.name}</option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 input-cont pos-rel my-0 mt-2">
                      {/* <i class="fa fa-chevron-down select_down_arrow_adjustable" ></i> */}
                      <div className="form-group my-0">
                        <label htmlFor="inputState">
                          {" "}
                          Who is the Appoinment For?
                        </label>

                        <select value={state.appoint} onChange={ImputVal} name="appoint" id="inputState" className="form-control">
                          <option selected disabled value={null}>Select Appointment</option>
                          {type?.map((item, index) => {
                            return <option key={index + 500} value={item.id}>{item.name}</option>
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-3 my-0 mt-2 input-cont2">
                      <p className="label">Select Date</p>
                      {/* `${d.getFullYear()}-0${d.getMonth() + 1}-${d.getDate()}` */}
                      <input min={new_date} value={state.date} onChange={ImputVal} name="date" type="date" placeholder="MM - DD - YYYY" />
                    </div>
                    <div className="col-lg-3 my-0 mt-2 input-cont2">
                      <p className="label">Select Time</p>
                      <input value={state.time} onChange={ImputVal} name="time" type="time" placeholder="HH- MM" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="your-information">
                <p className="Appoinment-Request-title">Your Information</p>

                <div className="field-set">
                  <div className="row">
                    <div className="col-lg-6 input-cont mt-2">
                      <p className="label">First Name</p>
                      <input disabled value={`${selector?.user?.first_name}`} type="text" placeholder="John" />
                    </div>
                    <div className="col-lg-6 input-cont mt-2">
                      <p className="label">Last Name</p>
                      <input disabled value={` ${selector?.user?.last_name}`} type="text" placeholder="Doe" />
                    </div>
                    <div className="col-lg-6 input-cont mt-2">
                      <p className="label">Email Address</p>
                      <input disabled value={`${selector?.user?.email}`} type="text" placeholder="Johndoe@gmail.com" />
                    </div>
                    <div className="col-lg-6 input-cont2 mt-2">
                      <p className="label">Date of birth</p>
                      <input type="date" disabled value={`${selector?.user?.date_of_birth}`} placeholder="MM - DD - YYYY" />
                    </div>
                    <div className="book-appoinment-container">
                      <button
                        onClick={submitButton}
                        className="btn book-appoinment-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="billing-request">
              Billing Request
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              {defaultCard ?
                <Row>
                  <Col xs={12} md={8}>
                    <h3 className="summary">Summary</h3>
                    <p className="summary-paragraph">
                      Counselling  session for 30 minutes
                    </p>
                    <p className="summary-paragraph2">{state?.date}{" "} {state?.time?.slice?.(0, 10)}</p>
                  </Col>
                  <Col xs={6} md={4}>
                    <div className="summary-fees-container">
                      <p className="summary-paragraph-fees Fees_heading">Fee</p>
                      <p className="summary-paragraph-fees2">
                        {/* {} */}
                        {services?.filter((item) => {
                          return item?.id == state?.service
                        }).map((itemz, index) => {
                          return <span key={index + 8812}>$ {itemz?.price}</span>
                        })}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} >
                    <div className="summary-fees-container">
                      <div>
                        <p className="summary-paragraph-fees"><small>Amount would be deducted from your default card however you can change default card option from your profile </small> </p>
                        <p className="summary-paragraph-fees">Selected Card : <b>XXXXXXXXXX{defaultCard?.card_last4}</b> </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                :
                <Row>
                  <Col xs={12}>
                    <p className="summary-paragraph-fees"><h4>No default card found add your card from <Link to="/billing-details">Settings</Link></h4> </p>
                  </Col>
                </Row>
              }
            </Container>
            {/* <div className="main-rating-modal">
              <div>
                <p className="rating-text-modal"></p>
              </div>
              <div className="rating-wrapper-modal">
                <div className="start-ul-modal">expiry / vcc</div>
              </div>
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            {defaultCard
              ?
              <Button className="confirm-booking" onClick={StoreApoointMentFunc}>
                Confirm Booking
              </Button>
              :
              null
            }
          </Modal.Footer>
        </Modal>

        <Modal
          show={showanothermodal}
          onHide={handleCloseAnotherModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal title</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <div className="thank-you-contanair">
              <h2 className="thank-you-modal">
                Thank You For Booking The Appoinment
              </h2>
              <p className="thank-you-para">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard
              </p>
            </div>
          </Modal.Body>

          <div className="download-appoinment-container">
            <a href={`${selector?.siteSetting?.form_url}${selector?.siteSetting?.form}`} target="_blank" download>
              <Button
                className="download-appoinment"
                onClick={handleCloseAnotherModal}
              >
                Download Appoinment Form
              </Button>
            </a>
            {/* <Button className="cancel-appoinment">Cancel Appoinment</Button> */}
          </div>
        </Modal>
      </section>

      <Footer />
    </div>
  );
}

export default BookAnAppoinment;
