import { ERROR_TYPE , REMOVE_ERROR } from '../services/Types';

export const ErrorAction = (e) => {
    console.log(e, 'from action page workinggg');
    return {
        type: ERROR_TYPE,
        payload: e
    }
};


export const RemoveError = (e) => {
    console.log(e, 'from action page workinggg');
    return {
        type: REMOVE_ERROR,
        payload: e
    }
};