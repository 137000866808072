import axios from "axios";
import { toast } from "react-toastify";
import { ErrorAction } from "../Errors/Action";
import {
    headers, responseError, Login, SignUp, ForgotPasswordUrl, ResetPasswordUrl, AboutUsUrl, PrivacyPolicyUrl, FaqUrl, HomePageUrl, ContactUsUrl, VideosUrl, SiteSettingUrl, NewsLetterUrl, ServicesGetAllUrl, CouncelUrl, ProfileUpdate, headersWithImage, ChangePasswordUrl, headersWithToken, PostReviewUrl, GetUpcomingBookingsUrl, GetPreviousBookingsUrl, GetCanceledBookingsUrl, UploadFileFormUrl, CancelAppointmentUrl, BlogUrl, BlogDetails, AppointmentServiceUrl, AppointmentTypeUrl, StoreAppointmentUrl, TestimonailApiUrl, InfoAfterLogin, GetDefaultCardUrl, updateDefaultCardUrl, DeleteCardUrl, AddCardUrl
} from "../services/Apis";
import { showLoader } from "../services/Loader";
import { SAVE_TOKEN, UPDATE_PROFILE } from '../services/Types';

export const saveInfo = (token, userInfo, checked, password , email) => {
    // console.log("save token from action auth", password);
    return {
        type: SAVE_TOKEN,
        payload: { token, userInfo, checked, password , email }
    }
}

export const UpdateProfileData = (e) => {
    // console.log("update profile data auth action", e)
    return {
        type: UPDATE_PROFILE,
        payload: { e }
    }
}

export const SetSiteSettings = (e) => {
    // console.log("ffrom action site setiing", e)
    return {
        type: "SITE_SETTING",
        payload: e
    }
}

// login api starts
export const LoginApi = (data, checked) => {
    // console.log('from auth service', data?.password);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(`${Login}`, data, headers)
            // console.log("response from login", res);
            dispatch(saveInfo(res.data.response.data.token, res.data.response.data.user, checked, data?.password, data?.email))
            myResolve(true);
            showLoader('none');
        } catch (e) {
            let errorHandler = responseError(e);
            showLoader('none');
            console.log("Error found", e);
            // toast.error("Not Working",e)
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
};

// signup api
export const SignupApi = (data) => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(SignUp, data, headers);
            // console.log("response from signup", res);
            toast.success("Verify Your Email To Access your account");
            showLoader('none');
            myResolve(true);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            // console.log('from auth action', errorHandler)
            showLoader('none');
            myReject(false);
        }
    });
};

// forgot password
export const ForgotPasswordApi = (data) => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(ForgotPasswordUrl, data, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            // console.log(e?.response.data.message);
            toast.error(e?.response?.data?.message);
            // console.log('from auth action', errorHandler)
            showLoader('none');
            myReject(e);
        }
    });
};

// reset password
export const ResetPasswordApi = (data) => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(ResetPasswordUrl, data, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
};

// edit profile

export const UpdateProfile = (data, token) => {
    // console.log('from contact api', token);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(`${ProfileUpdate}`, data, headersWithImage(token));
            // console.log("response from update profile", res);
            dispatch(UpdateProfileData(res.data.response.data));
            showLoader("none");
            myResolve(true);
        } catch (e) {
            showLoader('none');
            console.log(e, ' from upate profile api');
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            myReject(false);
        }
    })
}

// change password

export const changePasswordApi = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(ChangePasswordUrl, data, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    })
}

// aboutus
export const AboutUsApi = () => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(AboutUsUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
};

// privacy policy
export const PrivacyPolicyApi = () => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(PrivacyPolicyUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
};

// faqs
export const FaqsApi = () => {
    // console.log("from signup api", data);
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(FaqUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
};

// home page section

export const homeApi = () => {
    showLoader('flex');
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(HomePageUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// contact us

export const ContactUsApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(ContactUsUrl, data, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// get videos

export const VideosApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(VideosUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// video pagination

export const VedioPaginateApi = (Apiurl) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(Apiurl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// site settings

export const siteSettingApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(SiteSettingUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// news letter

export const NewsLetterApi = (data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(NewsLetterUrl, data, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// get services

export const ServicesGetAllApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(ServicesGetAllUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// service paginate

export const ServicePaginateApi = (url) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(url, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// CouncelApi

export const CouncelApi = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(CouncelUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// Post Review Api

export const PostReviewApi = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(PostReviewUrl, data, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// get booking api

export const GetBookingDetailsApi = (token, type) => {
    // console.log(token, type);
    let url = "";
    if (type === 1) {
        url = GetPreviousBookingsUrl;
    } else if (type === 2) {
        url = GetUpcomingBookingsUrl;
    } else {
        url = GetCanceledBookingsUrl;
    }
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(url, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// appointment pagination
export const AppointmentPagination = (token, url) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(url, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// UPload Form
export const UploadFormAPiCall = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(UploadFileFormUrl, data, headersWithImage(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// cancel appointment 

export const CancelAppointme = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(CancelAppointmentUrl, data, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// get blog

export const BlogGetApiCall = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(BlogUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// blog pagination

export const BlogPagination = (url) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(url, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const SingleBlogAPiCall = (id) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(`https://dream-builder-backend.developer-ourbase-camp.com/api/blogs/blog-detail?id=${id}`, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const SubmitCommentAPiCall = (data, check) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(BlogDetails, data, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const getAppointmentServiceApiCall = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(AppointmentServiceUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const getAppointmentTypeApiCall = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(AppointmentTypeUrl, headers);
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const StoreAppointmentBookingApiCall = (token, data) => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(StoreAppointmentUrl, data, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const getTesitmonialApiCall = () => {
    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(TestimonailApiUrl, headers());
            showLoader('none');
            myResolve(res);
        } catch (e) {
            let errorHandler = responseError(e);
            dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const InfoAfterLoginApiCall = (token) => {

    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(InfoAfterLogin, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

// get cards

export const GetDefaultCardApiCall = (token) => {

    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.get(GetDefaultCardUrl, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const UpdateCardApiCall = (token, data) => {

    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(updateDefaultCardUrl, data, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const DeleteCardApiCall = (token, data) => {

    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(DeleteCardUrl, data, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}

export const AddCardApiCall = (token, data) => {

    showLoader("flex");
    return (dispatch) => new Promise(async (myResolve, myReject) => {
        try {
            let res = await axios.post(AddCardUrl, data, headersWithToken(token));
            showLoader('none');
            myResolve(res);
        } catch (e) {
            // let errorHandler = responseError(e);
            // dispatch(ErrorAction(errorHandler));
            showLoader('none');
            myReject(e);
        }
    });
}