import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RemoveError } from '../Redux/Errors/Action';



const ToastMessages = () => {
    const reduxState = useSelector((state) => state.ErrorReducer);
    // console.log('authh msg', reduxState);
    // const notify = () => toast("MEssage");
    // notify();
    const dispatch = useDispatch();

    useEffect(() => {
        reduxState.error.map((item,key) => {
            dispatch(RemoveError(key));
            toast.error(item?.message)
        })
    }, [reduxState]);

    return (
        <ToastContainer />
    )
};

export default ToastMessages;