import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import '../../assets/css/Footer.css'
import { NewsLetterApi } from "../../Redux/Auth/Auth_Action";

const Newsletter = () => {
	const [state, setState] = useState("");
	const dispatch = useDispatch();
	const SubmitNewsLetter = (e) => {
		e.preventDefault();
		let sendData = {
			email: state
		}
		dispatch(NewsLetterApi(sendData))
			.then((res) => {
				// console.log(res);
				toast.success("Newsletter subscribe successfully");
				setState("");
			})
			.catch((err) => {
				console.log(err);
			})
	}
	return (
		<>
			<section className="newsletter">
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="row newsletter-bg">
								<div className="col-lg-5">
									<div className="content-wrapper">
										<h6 className="Newsletter-heading">Newsletter</h6>
										<h3 className="subscribe-Newsletter-heading">Subscribe to our newsletter</h3>
										{/* <p className="subscribe-paragraph-heading">
										We're here to inform with business tactics need funding and which are.
										</p> */}
									</div>
								</div>
								<div className="col-lg-7">
									<form onSubmit={SubmitNewsLetter}>
										<div className="newsletter-input-wrapper">
											<input
												required
												value={state}
												onChange={(e) => { setState(e.target.value) }}
												name="email"
												type="email"
												placeholder="Enter Your Email"
												className="form-control"
											/>
											<button type="submit" className="btn bt-susbcribe">
												Subscribe
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Newsletter;