import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/css/Faqs.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { FaqsApi } from "../../Redux/Auth/Auth_Action";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

function Faqs() {
	const [state, setState] = useState();

	const dispatch = useDispatch();
	const selector = useSelector((state) => state.Auth);
	// console.log(selector?.siteSetting?.faq);
	const FaqsFunction = () => {
		dispatch(FaqsApi())
			.then((res) => {
				setState(res?.data?.response?.data?.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		FaqsFunction();
	}, []);

	return (
		<>
			<section className="common-banner bgBanner">
				<Header />
				<div className="container">
					<div className="url-container">
						<h2 className="title">FAQS</h2>
					</div>
				</div>
			</section>

			<section className="faq">
				<div className="container">
					<div className="row">
						<div className="col-lg-1"></div>
						<div className="col-lg-10">
							<div className="faq-wrapper">
								<div className="faq-heading-wrapper">
									<p className="sm-tag">FAQs</p>
									<h2 className="frequently-ask-questions">
										Frequently Asked Questions
									</h2>
									<p className="main-para">{selector?.siteSetting?.faq}</p>
								</div>

								<div className="accordion mt-5" id="accordionExample">
									{/* {faqsData?.map((item, index) => {
                    return (
                      <div className="accordion-item mb-4" key={index}>
                        <h2 className="accordion-header mb-0" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#${item?.question}${item?.id}`}
                            aria-expanded="true"
                            aria-controls={`${item?.question}${item?.id}`}
                          >
                            {item?.question}
                          </button>
                        </h2>
                        <div
                          id={`${item?.question}${item?.id}`}
                          className="accordion-collapse collapse show"
                          aria-labelledby={item?.id}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">{item?.answer}</div>
                        </div>
                      </div>
                    );
                  })} */}
									<div className="faqs">
										{state?.map((item, index) => {
											return (
												<div key={index + 4561} className="col-lg-12 mb-4">
													<Accordion
														className="accordian"
														expanded={expanded === index}
														onChange={handleChange(index)}
													>
														<AccordionSummary
															className="accordianSummary"
															expandIcon={
																expanded === index ? (
																	<RemoveIcon className="text-white" />
																) : (
																	<AddIcon className="text-white" />
																)
															}
															// expandIcon={<ExpandMoreIcon  />}
															aria-controls="panel1bh-content"
															id="panel1bh-header"
														>
															<p>{item?.question}</p>
														</AccordionSummary>
														<AccordionDetails className="accordionDetails">
															<p
																className="faqs-paragraph"
																dangerouslySetInnerHTML={{
																	__html: item?.answer,
																}}
															/>
															{/* {item?.answer}
                              </p> */}
														</AccordionDetails>
													</Accordion>
												</div>
											);
										})}
									</div>

									{/* <div className="m-4">
                    <div className="accordion" id="myAccordion">
                      {state?.map((item) => {
                        return (
                          <div key={item?.id} className="accordion-item">
                            <h2 className="accordion-header" id={`heading${item?.id}`}>
                              <button
                                type="button"
                                className="accordion-button collapsed"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapse${item?.id}`}
                              >
                                {item?.question}
                              </button>
                            </h2>
                            <div
                              id={`collapse${item?.id}`}
                              className="accordion-collapse collapse"
                              data-bs-parent="#myAccordion"
                            >
                              <div className="card-body">
                                <p className="faqs-paragraph">
                                  {item?.answer}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div> */}
								</div>
							</div>
						</div>
						<div className="col-lg-1"></div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default Faqs;
