import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import '../../assets/css/ServiceRate.css'
import { ServicesGetAllApi } from '../../Redux/Auth/Auth_Action';

function ServiceRates() {
   
    return (
        <div className='services'>
            <div className='container'>
                {/* <h2>Services We Provide For Our Clients</h2> */}
                <h2>Our Offerings</h2>
                <div className='row service_card'>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-8">
                        <div className='price-cont'>
                            <p>Service Name</p>
                            <p>$400.00</p>
                        </div>
                        <p>Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                        <Link to="/">Book Now</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceRates