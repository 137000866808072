import React, { useEffect, useState } from "react";
import "../../assets/css/Blogs.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { service1, service2, service3, service4 } from "../../constant";
import { Link, useNavigate } from "react-router-dom";
import { GoCalendar } from "react-icons/go";
import { FaUserAlt, FaLinkedinIn } from "react-icons/fa";
import { TbMessageCircle } from "react-icons/tb";
import { FaFacebookF } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import { BsInstagram } from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
	BlogGetApiCall,
	BlogPagination,
	homeApi,
} from "../../Redux/Auth/Auth_Action";
import {
	FacebookShareButton,
	TwitterShareButton,
	InstapaperShareButton,
} from "react-share";
import VideoPlayer from "react-background-video-player";
import { LinkedInEmbed } from "react-social-media-embed";

function Blogs() {
	const [homestate, setHomestate] = useState();
	const dispatch = useDispatch();
	const [state, setState] = useState(null);
	const [links, setLinks] = useState(null);
	const navigate = useNavigate();
	const getBlogApiFunc = () => {
		dispatch(BlogGetApiCall())
			.then((res) => {
				setState(res?.data?.response?.data?.data);
				setLinks(res?.data?.response?.data?.links);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const MenuPagination = (e) => {
		dispatch(BlogPagination(e))
			.then((res) => {
				setState(res?.data?.response?.data?.data);
				setLinks(res?.data?.response?.data?.links);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(() => {
		getBlogApiFunc();
		dispatch(homeApi())
			.then((res) => {
				// console.log(res.data.response.data);
				setHomestate(res.data.response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const serviceData = [
		{
			id: 1,
			img: service1,
		},
		{
			id: 2,
			img: service2,
		},
		{
			id: 3,
			img: service3,
		},
		{
			id: 4,
			img: service4,
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="BLOG_PAGE">
			<section className="common-banner">
				<Header />
				<VideoPlayer
					className="video"
					src={`${homestate?.image_url}${homestate?.video}`}
					autoPlay={true}
					muted={true}
					preload="auto"
				/>
				<div className="container">
					<div className="url-container">
						<h2 className="title">Blogs</h2>
					</div>
				</div>
			</section>

			{/* <!-- Services We Provide For Our Clients Sec Start Here --> */}
			<section className="Blog-rates">
				<div className="container">
					<div className="col card-col">
						{state?.map((item, index) => {
							return (
								<div key={index} className="Blog-card">
									<div className="Blogs-img-box">
										<div className="row">
											<div className="col-md-4">
												<div className="Blogs-image">
													<img
														src={`${item?.image_url}${item?.image}`}
														alt=" "
														className="image-fluid w-100 img_blog_many_main"
													/>
												</div>
											</div>
											<div className="col-md-8">
												<div className="Blogs_details">
													<div className="Blogs-name">
														<p>Counselling </p>
														<h4>{item?.title}</h4>
													</div>
													<div className="timeLine">
														<div className="calender">
															<GoCalendar />
															<p>{item?.updated_at.slice(0, 10)}</p>
														</div>
														<span>/</span>
														<div className="userName">
															<FaUserAlt />
															<p>{item?.added_by}</p>
														</div>
														<span>/</span>
														<div className="comment">
															<TbMessageCircle />
															<p>({item?.comments.length}) Comment</p>
														</div>
													</div>
													<div className="blog_text_para">
														<h4>{item?.short_description}</h4>
													</div>
													<div className="socialMedia">
														<FacebookShareButton
															url={`${window.location.href}`}
															quote={`${item?.title}`}
															// hashtag={"#hashtag"}
															description={`${item?.description}`}
															className="Demo__some-network__share-button"
														>
															<div className="BoxColor">
																<i
																	className="fa fa-facebook ico_hght_wdth"
																	aria-hidden="true"
																></i>
															</div>
														</FacebookShareButton>
														<TwitterShareButton
															title={`${item?.title}`}
															url={`${window.location.href}`}
															hashtags={[`${item?.title}`]}
														>
															<div className="BoxColor">
																<i
																	className="fa fa-twitter ico_hght_wdth"
																	aria-hidden="true"
																></i>
															</div>
														</TwitterShareButton>

														{/* maaz */}
														{/* <InstapaperShareButton title="check_test title" description="check test descripotion" >
                              <div className="BoxColor">
                                <i
                                  className="fa fa-instagram ico_hght_wdth"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </InstapaperShareButton> */}

														<div
															onClick={() => {
																window.open(
																	`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${item?.title}
                            `,
																	`_blank`,
																);
															}}
															className="BoxColor px-3 cursor_pointer"
														>
															<i
																className="fa fa-linkedin ico_hght_wdth"
																aria-hidden="true"
															></i>
														</div>
														<div className="View_More_Page_Open">
															<button
																onClick={() => {
																	navigate(`/blog-detail/${item.id}`);
																}}
																className="btn"
															>
																View Details
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div className="row mb-4">
						<div className="col-lg-12 text-right">
							{links?.map((item, index) => {
								if (item.label === "&laquo; Previous") {
									item.label = "Previous";
									return (
										<button
											key={index + 100}
											onClick={() => {
												MenuPagination(item.url);
											}}
											disabled={item.url === null ? true : false}
											className="btn btn-light bt-pagination-all"
										>
											{item.label}
										</button>
									);
								} else if (item.label === "Next &raquo;") {
									item.label = "Next";
									return (
										<button
											key={index + 100}
											onClick={() => {
												MenuPagination(item.url);
											}}
											disabled={item.url === null ? true : false}
											className="btn btn-light bt-pagination-all"
										>
											{item.label}
										</button>
									);
								} else {
									return (
										<button
											key={index + 100}
											onClick={() => {
												MenuPagination(item.url);
											}}
											disabled={item.url === null ? true : false}
											className="btn btn-light bt-pagination-all"
										>
											{item.label}
										</button>
									);
								}
							})}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Services We Provide For Our Clients Sec End Here --> */}
			<Footer />
		</div>
	);
}

export default Blogs;
