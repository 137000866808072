import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import '../../assets/css/Login.css'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useDispatch } from "react-redux";
import { SignupApi } from "../../Redux/Auth/Auth_Action";
import { toast } from "react-toastify";
import VisibilityIcon from '@mui/icons-material/Visibility';

function Signup() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  const location = useLocation()
  let navigate = useNavigate();
  const d = new Date();
  const new_date = new Date().toISOString().slice(0, 10);
  let five_year_restrict = parseInt(new_date?.slice(0, 4));
  const [hideShowPass, setHideShowPass] = useState(false);
  const [hideShowNewPass, setHideShowNewPass] = useState(false);
  // console.log((five_year_restrict-5).toString() )
  // console.log(new_date);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    f_name: "", l_name: "", email: "", phone: "", password: "", cpassword: "", date_of_birth: ""
  });

  const InputVal = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  }

  const SubmitData = (e) => {
    e.preventDefault();
    // if (!agree) {
    //   toast.error("Agree to our terms and conditions");
    // } else
    if (state.password !== state.cpassword) {
      toast.error("password and confirm password not matched");
    }
    else if (state.password.length < 8) {
      toast.error("password must be 8 character limit");
    }
    else {
      let SignupData = {
        first_name: state.f_name,
        last_name: state.l_name,
        email: state.email,
        password: state.password,
        confirm_password: state.cpassword,
        phone_no: state.phone,
        date_of_birth: state.date_of_birth,
      }
      dispatch(SignupApi(SignupData))
        .then((res) => {
          // console.log(res);
          setTimeout(() => {
            navigate('/login');
          }, 4000)
        })
        .catch((e) => {
          console.log(e);
        })
    }



  }

  return (
    <>
      <section className="page-title">
        <Header
          bgColor={true}
        />
      </section>
      <section className="login">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-inner">
                <p className="signup-title">Sign Up</p>
                {/* <p className="main-para">
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy aliquyam erat, sed diam voluptua.
                </p> */}
                <div className="field-set">
                  <form onSubmit={SubmitData}>
                    <div className="row">
                      <div className="col-lg-6 input-cont">
                        <p className="login-label">First Name</p>
                        <input
                          required
                          name="f_name"
                          value={state.f_name}
                          onChange={InputVal}
                          type="text" placeholder="John" />
                      </div>

                      <div className="col-lg-6 input-cont">
                        <p className="login-label">Last Name</p>
                        <input
                          required
                          name="l_name"
                          value={state.l_name}
                          onChange={InputVal}
                          type="text" placeholder=" Doe" />
                      </div>

                      <div className="col-lg-12 input-cont2">
                        <p className="login-label">Email</p>
                        <input
                          required
                          name="email"
                          value={state.email}
                          onChange={InputVal} type="email" placeholder="John Doe@gmail.com" />
                      </div>

                      <div className="col-lg-6 input-cont">
                        <p className="login-label">Phone Number</p>
                        <input
                          required
                          name="phone"
                          value={state.phone}
                          onChange={InputVal}
                          type="number" placeholder="121232323" />
                      </div>

                      <div className="col-lg-6 input-cont">
                        <p className="login-label">Date of Birth</p>
                        <input required
                          name="date_of_birth"
                          // min={`1900-01-01}`}
                          // max={`2022-01-04`}
                          // max={`${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`}
                          value={state.date_of_birth}
                          onChange={InputVal} max={`${(five_year_restrict - 5).toString()}-12-31`} type="date" />
                      </div>

                      <div className="col-lg-6 input-cont2">
                        <p className="login-label">Password</p>
                        <div className="pos-rel">
                          <VisibilityIcon className="visiblity_icon_posb" onMouseLeave={() => { setHideShowPass(false) }} onClick={() => { setHideShowPass(true) }} />
                          <input required
                            name="password"
                            value={state.password}
                            onChange={InputVal}
                            type={hideShowPass ? "text" : "password"}
                            placeholder="********" />
                        </div>

                      </div>
                      <div className="col-lg-6 input-cont2">
                        <p className="login-label">Confirm Password</p>
                        <div className="pos-rel">
                          <VisibilityIcon className="visiblity_icon_posb" onMouseLeave={() => { setHideShowNewPass(false) }} onClick={() => { setHideShowNewPass(true) }} />
                          <input required
                            name="cpassword"
                            value={state.cpassword}
                            onChange={InputVal}
                            type={hideShowNewPass ? "text" : "password"}
                            placeholder="********" />
                        </div>

                      </div>
                    </div>
                    <div className="signup-btn">
                      <button type="submit" className="btn signin-btn">Sign up</button>
                    </div>
                  </form>

                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Signup;
