import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import Signup from "../pages/Auth/Signup";
import Contact from "../pages/Contact/Contact";
import ServicesAndRate from "../pages/ServicesAndRate/ServicesAndRate";
import Faqs from "../pages/Faqs/Faqs";
import Home from "../pages/Home/Home";
import MyPostReview from "../pages/Profile/MyPostReview";
import MyAppoinment from "../pages/Profile/MyAppoinment";
import Profile from "../pages/Profile/Profile";
import ServiceRates from "../pages/Services/ServiceRates";
import Test from "../pages/Test";
import ProtectedRoutes from "./ProtectedRoutes";
import AboutUs from "../pages/AboutUs/AbouUs";
import Counselling from "../pages/Counselling/Counselling";
import Videos from "../pages/Videos/Videos";
import Blogs from "../components/Blogs/Blogs";
import ProductsDetails from "../pages/Details/ProductsDetails";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndCOnditions from "../pages/TermsAndConditions/TermsAndCOnditions";
import BookAnAppoinment from "../pages/BookAppointment/BookAnAppoinment";
import BillingDetails from "../pages/Profile/BillingDetails";
import ChangePassword from "../pages/Profile/ChangePassword";
import Opportunities from "../pages/Opportunity/Opportunities";
// import ProtectedRoutes from "./ProtectedRoutes";

function PublicRoutes(props) {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="signup" element={<Signup />} />
					<Route path="login" element={<Login />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route path="reset-password" element={<ResetPassword />} />
					<Route path="contact" element={<Contact />} />
					<Route path="services" element={<ServicesAndRate />} />
					<Route path="about-us" element={<AboutUs />} />
					<Route path="opportunities" element={<Counselling />} />
					<Route path="service-rates" element={<ServiceRates />} />
					<Route path="videos" element={<Videos />} />
					<Route path="faqs" element={<Faqs />} />
					<Route path="blogs" element={<Blogs />} />
					{/* <Route path="opportunities" element={<Opportunities />} /> */}

					<Route path="test" element={<Test />} />
					<Route path="blog-detail/:id" element={<ProductsDetails />} />
					<Route path="privacy-policy" element={<PrivacyPolicy />} />
					<Route path="terms-&-conditions" element={<TermsAndCOnditions />} />

					<Route
						path="/book"
						element={
							<ProtectedRoutes>
								<BookAnAppoinment />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/profile"
						element={
							<ProtectedRoutes>
								<Profile />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/my-review"
						element={
							<ProtectedRoutes>
								<MyPostReview />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/my-appoinment"
						element={
							<ProtectedRoutes>
								<MyAppoinment />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/billing-details"
						element={
							<ProtectedRoutes>
								<BillingDetails />
							</ProtectedRoutes>
						}
					/>
					<Route
						path="/change-password"
						element={
							<ProtectedRoutes>
								<ChangePassword />
							</ProtectedRoutes>
						}
					/>
					<Route path="*" element={<Home />} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default PublicRoutes;
