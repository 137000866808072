import React, { useEffect } from "react";
import "../../assets/css/AboutUs.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { homeAbout, QuoteImage } from "../../constant";
import {
	CircularProgressbar,
	CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { buildStyles } from "react-circular-progressbar";
import { useDispatch } from "react-redux";
import { AboutUsApi, homeApi } from "../../Redux/Auth/Auth_Action";
// import Box from '@mui/material/Box';
// import Tab from '@mui/material/Tab';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import VideoPlayer from "react-background-video-player";
import { useState } from "react";

function AboutUs() {
	const [state, setState] = useState();
	const [homestate, setHomestate] = useState();
	const dispatch = useDispatch();
	const percentage1 = 78;
	const percentage2 = 40;
	const percentage3 = 98;
	const percentage4 = 50;

	const [value, setValue] = React.useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const AboutUsApiFunction = () => {
		dispatch(AboutUsApi())
			.then((res) => {
				setState(res?.data?.response?.data);
				// console.log(res?.data?.response?.data);
				// console.log(res);
			})
			.catch((err) => {
				console.log(err);
			});
		dispatch(homeApi())
			.then((res) => {
				// console.log(res.data.response.data);
				setHomestate(res.data.response.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};
	console.log(homestate);

	useEffect(() => {
		AboutUsApiFunction();
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="About-Us-Page">
			<section className="common-banner">
				<Header />
				<VideoPlayer
					className="video"
					src={`${homestate?.image_url}${homestate?.video}`}
					autoPlay={true}
					muted={true}
					preload="auto"
				/>
				<div className="container">
					<div className="url-container">
						<h2 className="title">About Us</h2>
					</div>
				</div>
			</section>

			{/* <!-- Welcome To Dream Counselling Builder Sec Start Here --> */}

			<section className="about-us">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div
								dangerouslySetInnerHTML={{ __html: state?.description }}
								className="about-us-text"
							></div>
						</div>
						<div className="col-lg-6">
							<div className="img-control">
								<img
									src={`${state?.image_url}${state?.image}`}
									alt=""
									className="image-fluid obj_fit_cover"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* <!--Welcome To Dream Counselling Builder Sec End Here --> */}

			{/* <!--Progressbar Sec Start Here --> */}

			<section className="about-us-progressbar">
				<div className="container">
					<div className="row">
						<div className="col-md-3">
							<div className="main-div-container">
								<div className="progress-bar-controller">
									<CircularProgressbarWithChildren
										value={state?.number1}
										// circleRatio={0.5}
										// strokeWidth={100}
										styles={buildStyles({
											strokeLinecap: "butt",
										})}
										text={state?.number1}
										className="text-price pos-rel"
									>
										{/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

										<div className="plus">+</div>
									</CircularProgressbarWithChildren>
								</div>
								<div className="progress-bar-main-text">
									<h5 className="text-main-heading">{state?.title1}</h5>

									<p className="text-heading-2">{state?.description1}</p>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="main-div-container">
								<div className="progress-bar-controller">
									<CircularProgressbarWithChildren
										styles={buildStyles({
											strokeLinecap: "butt",
										})}
										value={state?.number2}
										text={state?.number2}
									>
										{/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

										<div className="plus">+</div>
									</CircularProgressbarWithChildren>
								</div>

								<div className="progress-bar-main-text">
									<h5 className="text-main-heading">{state?.title2}</h5>

									<p className="text-heading-2">{state?.description2}</p>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="main-div-container">
								<div className="progress-bar-controller">
									<CircularProgressbarWithChildren
										value={state?.number3}
										styles={buildStyles({
											strokeLinecap: "butt",
										})}
										text={state?.number3}
									>
										{/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

										<div className="plus">+</div>
									</CircularProgressbarWithChildren>
								</div>
								<div className="progress-bar-main-text">
									<h5 className="text-main-heading">{state?.title3}</h5>

									<p className="text-heading-2">{state?.description3}</p>
								</div>
							</div>
						</div>
						<div className="col-md-3">
							<div className="main-div-container">
								<div className="progress-bar-controller">
									<CircularProgressbarWithChildren
										styles={buildStyles({
											strokeLinecap: "butt",
										})}
										value={state?.number4}
										text={state?.number4}
									>
										{/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}

										<div className="plus">+</div>
									</CircularProgressbarWithChildren>
								</div>
								<div className="progress-bar-main-text">
									<h5 className="text-main-heading">{state?.title4}</h5>

									<p className="text-heading-2">{state?.description4}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="about-us pt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="border-color">
								<img src={QuoteImage} alt="" className="quote-image" />

								<p className="quote">{state?.comment}</p>
								<p className="author">{state?.name}</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div>
								<p className="about_bottom_sec_head mb-0">
									Dream Builders Counseling
								</p>
								<h2 className="title-second-text mb-4">
									We Will Help For Better Health Relief from Stress
								</h2>
								<div className="tile" id="tile-1">
									{/* <div dangerouslySetInnerHTML={{ __html: state?.description }} className=""></div> */}

									{/* Nav tabs */}
									{/* <ul className="nav nav-tabs nav-justified" role="tablist">
                    <div className="slider" />
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        Lorem ipsum
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Lorem ipsum
                      </a>
                    </li>
                  </ul> */}
									<nav>
										<div className="nav nav-tabs" id="nav-tab" role="tablist">
											<button
												className=" tabs_btn active"
												id="nav-home-tab"
												data-bs-toggle="tab"
												data-bs-target="#nav-home"
												type="button"
												role="tab"
												aria-controls="nav-home"
												aria-selected="true"
											>
												{state?.sub_title}
											</button>
											<button
												className="tabs_btn"
												id="nav-profile-tab"
												data-bs-toggle="tab"
												data-bs-target="#nav-profile"
												type="button"
												role="tab"
												aria-controls="nav-profile"
												aria-selected="false"
											>
												{state?.sub_title2}
											</button>
										</div>
									</nav>
									<div className="tab-content" id="nav-tabContent">
										<div
											className="tab-pane abouttabs fade show active"
											id="nav-home"
											role="tabpanel"
											aria-labelledby="nav-home-tab"
										>
											<p className="nav-tabs-contain">{state?.content}</p>
										</div>
										<div
											className="tab-pane fade"
											id="nav-profile"
											role="tabpanel"
											aria-labelledby="nav-profile-tab"
										>
											<p className="nav-tabs-contain">{state?.content2}</p>
										</div>
									</div>
									{/* Tab panes */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!--Progressbar Sec End Here --> */}
			<section className="about-us pt-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div>
								{/* <p className="about_bottom_sec_head mb-0">
                  Welcome To Dream Counselling Builder
                </p> */}
								<h2 className="title-second-text mb-4">
									{state?.footer_title}
								</h2>
								<p>{state?.footer_description}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
}

export default AboutUs;
