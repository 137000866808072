import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import "../../assets/css/PrivacyPolicy.css";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { PrivacyPolicyApi } from "../../Redux/Auth/Auth_Action";

function PrivacyPolicy() {
  const [state, setState] = useState();
  const dispatch = useDispatch();
  const PrivacyPolicyFunction = () => {
    dispatch(PrivacyPolicyApi())
      .then((res) => {
        setState(res?.data?.response?.data?.data?.[0]);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    PrivacyPolicyFunction();
  }, []);
  return (
    <>
      <section className="common-banner bgBanner">
        <Header />
        <div className="container">
          <div className="url-container">
            <h2 className="title">Privacy Policy </h2>
          </div>
        </div>
      </section>

      {/* <!-- Privacy policy Sec End Here --> */}
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div>
                <h5 className="privacy-policy-text">
                  {state?.page_title}
                </h5>
                <div  dangerouslySetInnerHTML={{__html: state?.content}} className="privacy-policy-text3"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Privacy policy Sec End Here --> */}

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
