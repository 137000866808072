import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import "../../assets/css/ProductDetails.css";
import { detailmainimage, gameImage, UserComment } from "../../constant";
import { GoCalendar } from "react-icons/go";
import { FaUserAlt } from "react-icons/fa";
import { TbMessageCircle } from "react-icons/tb";
import Footer from "../../components/Footer/Footer";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { SingleBlogAPiCall, SubmitCommentAPiCall } from "../../Redux/Auth/Auth_Action";
import { toast } from "react-toastify";

function ProductsDetails() {
	const param = useParams();
	const myRef = useRef(null);
	const dispatch = useDispatch();
	const [state, setState] = useState(null);
	const [commentPost, setCommentPost] = useState({
		blog_id: param.id,
		// JSON?.parse(localStorage?.getItem("DREAM_BUILDER_COMMENT_INFO"))?.name ||
		// JSON?.parse(localStorage?.getItem("DREAM_BUILDER_COMMENT_INFO"))?.email ||
		name: JSON?.parse(localStorage?.getItem("DREAM_BUILDER_COMMENT_INFO"))?.name || "",
		email: JSON?.parse(localStorage?.getItem("DREAM_BUILDER_COMMENT_INFO"))?.email || "",
		description: "",
	})

	const [saveDetails, setSaveDetails] = useState(false);

	const getBlogApiFunc = () => {
		dispatch(SingleBlogAPiCall(param.id, saveDetails))
			.then((res) => {
				setState(res?.data?.response?.data);
			})
			.catch((err) => {
				console.log(err);
			})
	}



	const SubmitComment = (e) => {
		e.preventDefault();
		if (saveDetails) {
			localStorage.setItem("DREAM_BUILDER_COMMENT_INFO", JSON?.stringify(commentPost));
		}
		let sendData = {
			blog_id: param.id,
			name: commentPost.name,
			email: commentPost.email,
			description: commentPost.description
		}
		dispatch(SubmitCommentAPiCall(sendData))
			.then((res) => {
				toast.success("Comment added successfully")
				setTimeout(() => {
					window.location.reload();
				}, 2500);
			})
			.catch((err) => {
				console.log(err);
			})
	}

	useEffect(() => {
		getBlogApiFunc();

	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])
	// console.log(JSON?.parse(localStorage?.getItem("DREAM_BUILDER_COMMENT_INFO"))?.name);

	return (
		<>
			<section className="common-banner">
				<Header />
				<div className="container">
					<div className="url-container">
						<h2 className="title">Blog Detail</h2>
					</div>
				</div>
			</section>
			<section className="product_detail_page">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="blog-detail-image">
								<img
									src={`${state?.image_url}${state?.image}`}
									alt=""
									className="img-fluid detailImage"
								/>
							</div>
						</div>
						<div className="col-md-12 pro-details">
							<div className="timeLine">
								<div className="calender">
									<GoCalendar />
									<p>{state?.created_at.slice(0, 10)}</p>
								</div>
								<span>/</span>
								<div className="userName">
									<FaUserAlt />
									<p>{state?.added_by}</p>
								</div>
								<span>/</span>
								<div className="comment">
									<TbMessageCircle />
									<p>({state?.comments.length}) Comment</p>
								</div>
							</div>
						</div>

						<div className="col-md-12">
							<div className="Content_detail-page">
								<div className="content-first-section">
									
									<h4>{state?.title}</h4>
									<p>{state?.type}</p>
								</div>
								<div dangerouslySetInnerHTML={{ __html: state?.description }} className="content-second-section"></div>

								{/* <div className="content-third-section">
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's
										standard dummy text ever since the 1500s. Lorem Ipsum is
										simply dummy..Lorem Ipsum is simply dummy text of the
										printing and typesetting industry. Lorem Ipsum has been the
										industry's standard dummy text ever since the 1500s. Lorem
										Ipsum is simply dummy.Lorem Ipsum is simply dummy text of
										the printing and typesetting industry. Lorem Ipsum has been
										the industry's standard dummy text ever since the 1500s.
										Lorem Ipsum is simply dummy..Lorem Ipsum is simply dummy
										text of the printing and typesetting industry.
									</p>
								</div>
							 */}
							</div>
						</div>
						{/* <div className="col-md-12">
							<div className="row imagedivbottom">
								<div className="col-md-6">
									<div className="images-detail2-page">
										<img src={gameImage} alt="" className="img-fluid" />
									</div>
								</div>
								<div className="col-md-6">
									<div className="details-content-section2">
										<h3>Lorem Ipsum is simply dummy text</h3>
										<p>
											Koluptate velit cillum dolre fugiat nula pariatur.
											Excepteur anim idet laborumed perspiciat und omnis iste
											natus goluptatem acusantium dolore mque lorem ipsum dolor
											sit amet cons ectetur adipisicing elit sed incididunt.
											Lorem ipsum dolor sit amet consectetur adipsicing elit sed
											do eiusmod tempor incididunt labore et dolore magna
											aliqua. Koluptate velit cillum dolre fugiat nula pariatur.
											Excepteur anim idet laborumed perspiciat und omnis iste
											natus goluptatem acusantium dolore mque lorem ipsum dolor
											sit amet cons ectetur adipisicing elit sed incididunt.
											Lorem ipsum dolor sit amet consectetur adipsicing elit sed
											do eiusmod tempor incididunt labore et dolore magna
											aliqua. Koluptate velit cillum dolre fugiat nula pariatur.
											Excepteur anim idet laborumed perspiciat und omnis iste
											natus
										</p>
									</div>
								</div>
							</div>
						</div>
						 */}
						<div className="col-md-12">
							<div className="reviewDetails">
								<h3>
									{state?.comments?.length} Replies to <q>{state?.title}</q>
								</h3>
								<div className="clientsReviews">
									<div className="reviewCard">
										{state?.comments.map((item, index) => {
											return (
												<div key={index + 3500} className="firstReviewCard">
													<img src={`${item?.avatar_url}${item?.avatar}`} alt="" className="img-fluid cpmment_image_roundround" />
													<div className="firstReview-Content-text">
														<p className="commentUsername">{item?.name}</p>
														<p className="commentDate">{item?.updated_at.slice(0, 10)}</p>
														<p className="usercommentsend">{item?.description}</p>
														{/* <p onClick={() => { myRef.current.scrollIntoView() }} className="replytext cursor_pointer">Reply:</p> */}
														<hr />
													</div>
												</div>
											)
										})}

									</div>
								</div>
							</div>
						</div>
						<div ref={myRef} className="col-md-12">
							<div className="commentSection sc_ex">
								<h4>Leave Comment</h4>
								<form onSubmit={SubmitComment}>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group  textInputdiv">
												<label htmlFor="exampleFormControlInput1">Your Name</label>
												<input
													required
													value={commentPost?.name}
													onChange={(e) => { setCommentPost({ ...commentPost, name: e.target.value }) }}
													type="text"
													className="form-control"
													id="exampleFormControlInput1"
													placeholder="name"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group textInputdiv">
												<label htmlFor="exampleFormControlInput1">
													Email
												</label>
												<input
													required
													value={commentPost?.email}
													onChange={(e) => { setCommentPost({ ...commentPost, email: e.target.value }) }}
													type="email"
													className="form-control"
													id="exampleFormControlInput1"
													placeholder="name@example.com"
												/>
											</div>
										</div>
									</div>
									<div className="form-group textareaInput">
										<label htmlFor="exampleFormControlTextarea1">
											Type Your Comment
										</label>
										<textarea
											required
											placeholder="Type Here "
											onChange={(e) => { setCommentPost({ ...commentPost, description: e.target.value }) }}
											className="form-control"
											id="exampleFormControlTextarea1"
											rows="3"
										></textarea>
									</div>
									<div className="conditionrowbc">
										<div className="row">
											<div className="col-md-9">
												<div className="checkboxdiv">
													<div className="form-check ">
														<input
															onChange={(e) => { setSaveDetails(e.target.checked) }}
															className="form-check-input inpy_checkbx_blogs position-static mr-2"
															type="checkbox"
															id="blankCheckbox"
															value="option1"
															aria-label="..."
														/>
														<label
															htmlFor="blankCheckbox"
															className="blankCheckbox mt-blog_detail_text_last mb-0"
														>
															<span className="mb-0 ">
																Save my name and email in this browser for the next time I comment.
															</span>
														</label>
													</div>
												</div>
											</div>
											<div className="col-md-3 text-right">
												<button className="btn btn-submit">Submit</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer />
		</>
	);
}

export default ProductsDetails;
